import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 55px;
    background-color: #f1f1f1;
    display: flex;
`

export const LogoContainer = styled.div`
    width: 250px;
    height: 100%;
    background-color: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Logo = styled.img`
    height: 25px;
`

export const MenuContainer = styled.div`
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
`

export const MenuItems = styled.div`

`

export const UserItems = styled.div`

`

export const Item = styled.div`
    cursor: pointer;
`

export const UserItem = styled.div`
    width: 150px;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 20px;

    &:hover {
        background-color: #e5e5e5;
    }
`