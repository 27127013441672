import React, { useEffect, useState } from 'react';

//Styled and native components
import { Container, Loader } from '../components/tables/Style'
import { AddButton } from '../components/tables/Buttons'

//React components
import { InputArea } from './Styles/Inputs';

//Modules
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import ReactQuill from 'react-quill';
import '../style/editorStyle.css';

//Hooks
import { withRouter } from 'react-router-dom';

//Services
import { addToCollection, queryCollection } from '../services/database'
import { uploadFile } from '../services/storage';

const EditEducationsMeta = ({ history }) => {
    //State
    // const [testState, setTestState] = useState('');
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        id: '',
        metatitle: '',
        metadescription: '',
        ogtitle: '',
        ogdescription: '',
        ogurl: '',
        ogimage: '',
        metakeywords: '',
        metacanonical: '',
        order_number: '',
    });
    const [editorData, setEditorData] = useState('');
    const [editorData2, setEditorData2] = useState('');

    //Effect
    useEffect(() => {
        queryCollection('educations', 'id', '==', parseInt(history.location.state.id))
            .then(async result => {
                let tempTimes = '';
                let tempPrices = '';
                let tempLicence = false;

                if (result.data[0].times)
                    tempTimes = await result.data[0].times.join();

                if (result.data[0].prices)
                    tempPrices = await result.data[0].prices.join();

                if (result.data[0].licence)
                    tempLicence = await result.data[0].licence;

                // result.data[0].times ?
                //     setInputs({ ...result.data[0], times: (result.data[0].times).join() })
                //     :
                //     setInputs({ ...result.data[0], times: '' })
                // result.data[0].prices ?
                //     setInputs({ ...result.data[0], prices: (result.data[0].prices).join() })
                //     :
                //     setInputs({ ...result.data[0], prices: '' })
                setInputs({ ...result.data[0], prices: tempPrices, times: tempTimes, licence: tempLicence });
                setEditorData(result.data[0].left_long_text?.replaceAll('style="text-align:', 'class="ql-align-'));
                setEditorData2(result.data[0].right_long_text?.replaceAll('style="text-align:', 'class="ql-align-'));
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Functions
    const handleSubmit = () => {
        addToCollection('educations', (history.location.state.id).toString(),
            {
                ...inputs,
                left_long_text: editorData.replaceAll('class="ql-align-', 'style="text-align:').replaceAll('<p><br></p>','<p></p>'),
                right_long_text: editorData2.replaceAll('class="ql-align-', 'style="text-align:').replaceAll('<p><br></p>','<p></p>'),
                order_number: parseInt(inputs.order_number),
                times: (inputs.times).split(','),
                prices: (inputs.prices).split(',')
            });
        history.push('educations');
    }

    const handlePaste = (event) => {
        const pastedHTML = event.clipboardData.getData('text/html');
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = pastedHTML;
        const processedHTML = processAlignment(tempDiv);
        setEditorData(processedHTML);
      };
    
      const processAlignment = (element) => {
        debugger;
        const alignedElements = element.querySelectorAll('[style*="text-align"]');
        alignedElements.forEach((el) => {
          const textAlign = el.style.textAlign;
          el.removeAttribute('class');
          el.setAttribute('style', `text-align: ${textAlign};`);
        });
        return element.innerHTML;
      };

    return (
        <Container>

            <h1 style={{ color: '#fff' }}>Redni broj:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, order_number: e.target.value });
                }}
                value={inputs.order_number}
            />
            <h1 style={{ color: '#fff' }}>meta title:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, metatitle: e.target.value });
                }}
                value={inputs.metatitle}
            />
             <h1 style={{ color: '#fff' }}>Meta description:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, metadescription: e.target.value });
                }}
                value={inputs.metadescription}
            />
            <h1 style={{ color: '#fff' }}>OG title:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, ogtitle: e.target.value });
                }}
                value={inputs.ogtitle}
            />
             <h1 style={{ color: '#fff' }}>OG description:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, ogdescription: e.target.value });
                }}
                value={inputs.ogogdescription}
            />
            <h1 style={{ color: '#fff' }}>OG url:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, ogurl: e.target.value });
                }}
                value={inputs.ogurl}
            />
             <h1 style={{ color: '#fff' }}>OG image:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, ogimage: e.target.value });
                }}
                value={inputs.ogimage}
            />
            <h1 style={{ color: '#fff' }}>Meta keywords:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, metakeywords: e.target.value });
                }}
                value={inputs.metakeywords}
            />
             <h1 style={{ color: '#fff' }}>Meta canonical:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, metacanonical: e.target.value });
                }}
                value={inputs.metacanonical}
            />
            

            <h1 style={{ color: '#fff' }}>Slika:</h1>
            {loading ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoading(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoading(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, image: result.link });
                            }
                            else alert('Something went wrong with file upload');
                        })
                    }}
                />
            }

            <InputArea
                disabled
                onChange={e => {
                    setInputs({ ...inputs, image: e.target.value });
                }}
                value={inputs.image}
            />
            <h1 style={{ color: '#fff' }}>Mobile Slika:</h1>
            {loading ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoading(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoading(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, mobile_image: result.link });
                            }
                            else alert('Something went wrong with file upload');
                        })
                    }}
                />
            }

            <InputArea
                disabled
                onChange={e => {
                    setInputs({ ...inputs, mobile_image: e.target.value });
                }}
                value={inputs.mobile_image}
            />

            <div style={{ height: 50 }} />
            
            <AddButton onClick={() => handleSubmit()}>
                <i className="fas fa-plus-circle"></i>
                <h1>Sačuvaj</h1>
            </AddButton>
        </Container >
    )
}

export default withRouter(EditEducationsMeta);
