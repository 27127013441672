import React, { useEffect, useState } from 'react';

//Styled and native components
import { Container, Loader } from '../components/tables/Style'
import { AddButton } from '../components/tables/Buttons'

//React components
import { InputArea } from './Styles/Inputs';

//Modules
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import ReactQuill from 'react-quill';
import '../style/editorStyle.css';

//Hooks
import { withRouter } from 'react-router-dom';

//Services
import { addToCollection, queryCollection } from '../services/database'
import { uploadFile } from '../services/storage';

const EditShopProducts = ({ history }) => {
    //State
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        id: '',
        image: '',
        title: '',
        subtitle: '',
        content: '',
        price: '',
        discount: ''
    });
    const [editorData, setEditorData] = useState('');
    const [editorData2, setEditorData2] = useState('');
    const [editorData3, setEditorData3] = useState('');

    //Effect
    useEffect(() => {
        queryCollection('shop_products', 'id', '==', parseInt(history.location.state.id))
            .then(async result => {
                console.log(result);
                if (result.data.length > 0) {
                    setInputs({ ...result.data[0] });
                    setEditorData(result.data[0].content);
                }
            })
    });

    //Functions
    const handleSubmit = () => {
        addToCollection('shop_products', (history.location.state.id).toString(),
            {
                ...inputs,
                price: parseFloat(inputs.price),
                discount: parseFloat(inputs.discount),
                content: editorData,
                short_description: editorData2,
                long_description: editorData3
            });
        history.push('shop-products');
    }

    return (
        <Container>

            <h1 style={{ color: '#fff' }}>Naslov:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, title: e.target.value });
                }}
                value={inputs.title}
            />

            <h1 style={{ color: '#fff' }}>Podnaslov:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, subtitle: e.target.value });
                }}
                value={inputs.subtitle}
            />

            <h1 style={{ color: '#fff' }}>Slika:</h1>
            {loading ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoading(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoading(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, image: result.link });
                            }
                            else alert('Something went wrong with file upload');
                        })
                    }}
                />
            }

            <InputArea
                //disabled
                onChange={e => {
                    setInputs({ ...inputs, image: e.target.value });
                }}
                value={inputs.image}
            />

            <h1 style={{ color: '#fff' }}>Cijena:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, price: e.target.value });
                }}
                value={inputs.price}
            />

            <h1 style={{ color: '#fff' }}>Popust: (npr. 0.3 = 30% popusta)</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, discount: e.target.value });
                }}
                value={inputs.discount}
            />

            <h1 style={{ color: '#fff' }}>Detaljni opis:</h1>
            <ReactQuill
                theme={'snow'}
                value={editorData ? editorData : '<p></p>'}
                onChange={e => {
                    console.log(e);
                    setEditorData(e);
                }}
                modules={{ toolbar: [[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline'], [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }], [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], ['link']] }}
                formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'align']}
            />

            <h1 style={{ color: '#fff' }}>Kratki opis:</h1>
            <ReactQuill
                theme={'snow'}
                value={editorData2 ? editorData2 : '<p></p>'}
                onChange={e => {
                    console.log(e);
                    setEditorData2(e);
                }}
                modules={{ toolbar: [[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline'], [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }], [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], ['link']] }}
                formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'align']}
            />

            <h1 style={{ color: '#fff' }}>Hyperlink sadržaj:</h1>
            <ReactQuill
                theme={'snow'}
                value={editorData3 ? editorData3 : '<p></p>'}
                onChange={e => {
                    console.log(e);
                    setEditorData3(e);
                }}
                modules={{ toolbar: [[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline'], [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }], [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], ['link']] }}
                formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'align']}
            />

            <div style={{ height: 50 }} />

            <AddButton onClick={() => handleSubmit()}>
                <i className="fas fa-plus-circle"></i>
                <h1>Sačuvaj</h1>
            </AddButton>
        </Container >
    )
}

export default withRouter(EditShopProducts);
