//React
import React, { useState, useEffect } from 'react';

//Components
import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'

//Services
import { getAllCollection } from '../services/database';

//Modules
import CsvDownload from 'react-json-to-csv'

const ShopOrders = () => {
    //State
    const [rows, setRows] = useState([]);

    //Effect
    useEffect(() => {
        getAllCollection('shop_orders')
            .then(res => {
                console.log(res);
                if (res.data.length > 0) {
                    setRows(res.data);
                }
            })
            .catch(e => console.log(e))
    }, [])

    //Functions
    const generateFileName = () => {
        const date = new Date();
        const temp = date.toString();
        let fileName = `${temp.slice(4, 7)}-${temp.slice(8, 10)}-${temp.slice(11, 15)}-${temp.slice(16, 18)}-${temp.slice(19, 21)}.csv`

        return fileName;
    }

    return (
        <Container>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PageTitle>Narudžbe</PageTitle>
                {rows.length > 0
                    ? <CsvDownload style={{ marginLeft: 20 }} data={rows} filename={generateFileName()} >Preuzmi CSV</CsvDownload>
                    : false
                }
            </div>

            <QuestionsContainer>
                {rows.length > 0
                    ? rows.map((item, index) => (
                        <Table style={{ marginBottom: 20 }} key={`seminar_data_${index}`}>
                            <tr>
                                <th>ID artikla: {item.product_id}</th>
                            </tr>
                            <tr>
                                <th>Naziv artikla: {item.product_name}</th>
                            </tr>
                            <tr>
                                <th>Cijena: {item.buying_price} KM</th>
                            </tr>
                            <tr>
                                <th>Datum narudžbe: {item.date}</th>
                            </tr>
                            <tr>
                                <th>Ime i prezime: {item.user}</th>
                            </tr>
                            <tr>
                                <th>Kompanija: {item.company}</th>
                            </tr>
                            <tr>
                                <th>Grad: {item.city}</th>
                            </tr>
                            <tr>
                                <th>Adresa: {item.address}</th>
                            </tr>
                            <tr>
                                <th>Radno mjesto: {item.workplace}</th>
                            </tr>
                            <tr>
                                <th>PDV/ID bro: {item.pdv_number}</th>
                            </tr>
                            <tr>
                                <th>Telefon: {item.phone}</th>
                            </tr>
                            <tr>
                                <th>Email: {item.email}</th>
                            </tr>
                            <tr>
                                <th>Broj licence: {item.licence}</th>
                            </tr>
                            <hr style={{ marginTop: 20 }} />
                        </Table>
                    ))
                    : false
                }

            </QuestionsContainer>
        </Container>
    )
}

export default ShopOrders;