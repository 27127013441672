import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./Auth";
import Dashboard from '../screens/Dashboard';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const  auth = useAuth();
    return (
        <Route
            {...rest}
            render={routeProps => auth.user?(<Dashboard>
                                                        <RouteComponent {...routeProps} />
                                                    </Dashboard>)
                                                  :(< Redirect to={"/login"} >
                                                    {auth.signout()}
                        </Redirect>
                    )
            }
        />
    );
};


export default PrivateRoute