import styled from 'styled-components';

export const Container = styled.div`
    width: 250px;
    background-color: #e5e5e5;
    min-height: 100vh;
`

export const DrawerItem = styled.div`
    cursor: ${props => (props.unclickable ? 'unset' : 'pointer')};
    height: 45px;
    background-color: ${props => (props.selected ? '#E30710' : props.dark ? '#ccc' : props.medium ? '#d5d5d5' : props.light ? '#dfdfdf' : '#e5e5e5')};
    border-bottom: .5px solid #bbb;
    display: flex;
    align-items: center;
    padding: 0 15px;

    &:hover {
        background-color: #E30710;

        p {
            color: #f1f1f1;
            font-weight: bold;
        }

        i {
            color: #f1f1f1;
            font-size: 20px;
        }
    }

    p {
        margin: 0;
        padding-left: 10px;
        color: ${props => (props.selected ? '#f1f1f1' : '#222831')};
        font-size: 14px;
        font-weight: ${props => (props.selected ? 'bold' : '400')};
    }

    i {
        color: ${props => (props.selected ? '#f1f1f1' : '#2d4059')};
        font-size: ${props => (props.selected ? '20px' : '16px')};
    }
`

export const Icon = styled.div`
    width: 20px;
`