import React, { useState, useCallback } from 'react';

import { validateEmail, validatePassword } from '../../validation/loginValidation'

import { Container, FormContainer, TitleContainer, Logo, ContentContainer, InputField, Label, Button } from './Style'
import { queryCollection } from '../../services/database';
// import app from '../../config/firebase';

import { withRouter, Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

const LoginForm = ({ history }) => {
    const [inputs, setInputs] = useState({
        // email: 'armin.skenderovic@revicon.info',
        // password: 'Armin2023'
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({
        email: false,
        password: false,
        submit: false
    });

    const auth = useAuth();

    const handleEmailInput = e => {
        setInputs({ ...inputs, email: e.target.value });
        validateEmail(e.target.value)
            .then(result => {
                result.valid ?
                    setErrors({ ...errors, email: false, submit: false })
                    :
                    setErrors({ ...errors, email: true, submit: result.message })
            });
    }

    const handlePasswordInput = e => {
        setInputs({ ...inputs, password: e.target.value });
        validatePassword(e.target.value)
            .then(result => {
                result.valid ?
                    setErrors({ ...errors, password: false, submit: false })
                    :
                    setErrors({ ...errors, password: true, submit: result.message })
            });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const submitForm = useCallback(
        async event => {
            try {
                queryCollection('users', 'email', '==', inputs.email).then(async result => {
                    if (result.data.length > 0) {
                        if(result.data[0].isAdmin){
                            await auth.signin(inputs.email, inputs.password);
                            history.push('/statistics');
                        } else {
                            setErrors({ ...errors, submit: "Korisnik nije admin" });
                        }
                    } else {
                        setErrors({ ...errors, submit: "Korisnik ne postoji" });
                    }
                });
            }
            catch (error) {
                console.log(error);
                setErrors({ ...errors, submit: error.message });
            }
        }
    );

    if (auth.user) {
        return <Redirect to={'/statistics'} />
    }

    return (
        <>
            <Container>
                <FormContainer>
                    <TitleContainer>
                        <Logo src={require('../../images/logo.png')} />
                        <h1>Prijava na administrativni portal</h1>
                    </TitleContainer>
                    <ContentContainer>
                        <Label>Email</Label>
                        <InputField value={inputs.email} onChange={e => handleEmailInput(e)} />

                        <Label>Lozinka</Label>
                        <InputField type={'password'} value={inputs.password} onChange={e => handlePasswordInput(e)} />

                        <Button style={{ marginTop: 20 }} onClick={() => submitForm()}><h1>Potvrdi</h1></Button>

                        <Label
                            textAlign={'center'}
                            margin={'15px 0 0 0'}
                            error
                        >
                            {errors.submit}
                        </Label>
                    </ContentContainer>
                </FormContainer>
            </Container>
        </>
    )
}

export default withRouter(LoginForm);