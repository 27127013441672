import firebase from '../config/firebase';

//ADD FILE TO STORAGE
export const uploadFile = (file) => {
    try {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(file.name);

        return new Promise((resolve) => {
            fileRef.put(file).then((e) => {
                if (e.state === 'success') {
                    fileRef.getDownloadURL().then(url => {
                        resolve({
                            status: 200,
                            link: url
                        })
                    })
                }
            })
        })
    }
    catch (error) {
        console.log(error)
    }
}