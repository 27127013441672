import React from 'react';

import MainNavigation from '../components/navigation/MainNavigation'
import SideDrawer from '../components/navigation/SideDrawer'

const Dashboard = (props) => {
    return (
        <>
            <MainNavigation />
            <div style={{ display: 'flex' }}>
                <SideDrawer />
                {props.children}
            </div>
        </>
    )
}

export default Dashboard;