import styled from 'styled-components';

export const Backdrop = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(209,45,156);
    background: linear-gradient(144deg, rgba(209,45,156,1) 0%, rgba(94,162,239,0.68) 100%);
    justify-content: center;
    align-items: center;
`

export const ModalContainer = styled.div`
    width: 60%;
    min-height: 75vh;
    max-height: 99vh;
    background-color: #f1f1f1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 1170px) {
        width: 90%;
    }
`

export const TitleContainer = styled.div`
    width: calc(100% - 20px);
    height: calc(20% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 0px 10px;

    h2 {
        margin: 0px;
        font-size: 22px;
        font-weight: bold;
        color: #222222;
        width: 80%;
        text-align: center;
        max-width: calc(100% - 96px);

        @media only screen and (max-width: 768px) {
            font-size: 18px;
        }
    }

    h6 {
        margin: 10px 0px 0px 0px;
        font-size: 18px;
        font-weight: lighter;
        color: #222222;

        @media only screen and (max-width: 768px) {
            font-size: 16px;
        }
    }
`

export const ProgressContainer = styled.div`
    width: 100%;
    height: 20%;

    @media only screen and (max-width: 768px) {
        margin-top: 20px;
    }
`

export const ContentContainer = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 60%;
    overflow-y: scroll;
`

export const ExitButton = styled.button`
    border: 0;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;

    img {
        width: 38px;
        height: 38px;
        object-fit: contain;
    }
`