import firebase from '../config/firebase';

//Create user
export const createUser = (email, password) => {
    try {
        firebase.auth().createUserWithEmailAndPassword(email, password);
    }
    catch (e) {
        console.log(e);
    }
}

// //Delete user
// export const deleteUser = (uuid) => {
//     try {
//         firebase.auth()
//     }
//     catch (e) {
//         console.log(e);
//     }
// }

