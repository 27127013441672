//React
import React from 'react';

//Styles
import {
    Container
} from './styles/TextInput';

const TextInput = ({ value, onChange, placeholder, label, error, disabled, lableStyle }) => {
    return (
        <Container
            error={error}
            disabled={disabled}
        >
            <label style={lableStyle}>
                {label}
            </label>

            <input
                disabled={disabled}
                placeholder={placeholder || ''}
                value={value??""}
                onChange={e => onChange ? onChange(e.target.value) : false}
            />
        </Container>
    )
}

export default TextInput;