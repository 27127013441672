import React, { useState } from 'react';

import { InputArea } from './Styles/Inputs';

import { getAllCollection, addToCollection } from '../services/database';
import { addSubcategoryService, addQuestionService } from '../services/bazaZnanja';

import { withRouter } from 'react-router-dom';

import styled from 'styled-components';

const Text = styled.p`
    color: #fff;
    margin-left: ${props => props.subcategory ? '50px' : props.question ? '100px' : props.answer ? '150px' : '0'};
`

const Button = styled.button`
    margin-top: 10px;
    margin-left: ${props => props.subcategory ? '50px' : props.question ? '100px' : '0'};
    max-width: 150px;
    height: 25px;
    background-color: ${props => props.bg ? props.bg : props.subcategory ? 'lightblue' : props.question ? 'lightgreen' : '#eee'};
    border: 0;
    border-radius: 4px;
    cursor: pointer;
`

const AddCategory = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    width: calc(50% + 30px);

    button {
        max-width: 50px;
        margin-top: 7.5px;
        height: 25px;
        margin-left: calc(100% - 50px);
        background-color: royalblue;
        border: 0;
        font-weight: bold;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
`

const AddSubcategory = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    width: calc(50% - 20px);
    margin-left: 50px;

    button {
        max-width: 50px;
        margin-top: 7.5px;
        height: 25px;
        margin-left: calc(100% - 50px);
        background-color: royalblue;
        border: 0;
        font-weight: bold;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
`

const AddQuestion = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    width: calc(50% - 70px);
    margin-left: 100px;

    button {
        max-width: 50px;
        margin-top: 7.5px;
        height: 25px;
        margin-left: calc(100% - 50px);
        background-color: royalblue;
        border: 0;
        font-weight: bold;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
`

const BazaZnanja = () => {
    //State
    const [categories, setCategories] = useState([]);

    const [showCategoryFields, setShowCategoryFields] = useState(false);
    const [showSubcategoryFields, setShowSubcategoryFields] = useState(false);
    const [showQuestionFields, setShowQuestionFields] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    // const [selectedQuestion, setSelectedQuestion] = useState(null);

    const [categoryInput, setCategoryInput] = useState('');
    const [subcategoryInput, setsubcategoryInput] = useState('');
    const [questionInput, setQuestionInput] = useState('');
    const [questionTitleInput, setQuestionTitleInput] = useState('');
    const [answerInput, setAnswerInput] = useState('');

    //Functions
    const handleLoadQuestions = () => {
        getAllCollection('knowledge_vault').then(result => {
            if (result.data) {
                setCategories(result.data);
            }
            else {
                alert('Došlo je do greške pri učitavanju pitanja.');
            }
        })
    }

    const addCategory = () => {
        if (categoryInput) {
            console.log('add category');

            addToCollection(
                'knowledge_vault',
                categories.length > 0 ? (Math.max.apply(Math, categories.map(function (o) { return o.id; })) + 1).toString() : '0',
                {
                    id: categories.length > 0 ? (Math.max.apply(Math, categories.map(function (o) { return o.id; })) + 1).toString() : '0',
                    title: categoryInput,
                    children: {}
                }
            );

            setShowCategoryFields(false);
            setCategoryInput('');

            getAllCollection('knowledge_vault').then(result => {
                setCategories(result.data);
            })
        }
    }

    const addSubcategory = () => {

        if (subcategoryInput) {
            console.log('add sub-category');

            addSubcategoryService(
                (Object.values(categories)[selectedCategory].id).toString(),
                Object.values(categories)[selectedCategory].children,
                Object.values(Object.values(categories)[selectedCategory].children).length > 0 ? (Math.max.apply(Math, Object.values(Object.values(categories)[selectedCategory].children).map(function (o) { return o.id; })) + 1).toString() : '0',
                {
                    id: Object.values(Object.values(categories)[selectedCategory].children).length > 0 ? (Math.max.apply(Math, Object.values(Object.values(categories)[selectedCategory].children).map(function (o) { return o.id; })) + 1).toString() : '0',
                    title: subcategoryInput,
                    children: {}
                }
            )
                .then(res => console.log(res));

            setShowSubcategoryFields(false);
            setsubcategoryInput('');

            getAllCollection('knowledge_vault').then(result => {
                setCategories(result.data);
            })
        }
    }

    const addQuestion = () => {
        if (questionInput && questionTitleInput && answerInput) {
            console.log('add question');

            addQuestionService(
                (Object.values(categories)[selectedCategory].id).toString(),
                Object.values(categories)[selectedCategory].children,
                (Object.values(Object.values(categories)[selectedCategory].children)[selectedSubcategory].id).toString(),
                Object.values(Object.values(categories)[selectedCategory].children)[selectedSubcategory].children,
                Object.values(Object.values(Object.values(categories)[selectedCategory].children)[selectedSubcategory].children).length > 0 ? (Math.max.apply(Math, Object.values(Object.values(Object.values(categories)[selectedCategory].children)[selectedSubcategory].children).map(function (o) { return o.id; })) + 1).toString() : '0',
                {
                    id: Object.values(Object.values(Object.values(categories)[selectedCategory].children)[selectedSubcategory].children).length > 0 ? (Math.max.apply(Math, Object.values(Object.values(Object.values(categories)[selectedCategory].children)[selectedSubcategory].children).map(function (o) { return o.id; })) + 1).toString() : '0',
                    title: questionTitleInput,
                    question: questionInput,
                    answer: answerInput,
                }
            )
                .then(res => console.log(res));

            setShowQuestionFields(false);
            setQuestionInput('');
            setQuestionTitleInput('');
            setAnswerInput('');

            getAllCollection('knowledge_vault').then(result => {
                setCategories(result.data);
            })
        }
    }

    return (
        <div style={{ marginLeft: 35, paddingBottom: 100 }}>
            {categories.length === 0 ?
                <div style={{ marginBottom: 30 }}>
                    <Button bg={'orange'} onClick={() => handleLoadQuestions()}>
                        Učitaj sva pitanja (!)
                    </Button>
                </div>
                : false
            }

            {categories.length > 0 ?
                categories.map((category, categoryIndex) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text>+++ {category.title}</Text>
                        {category.children && Object.values(category.children).length > 0 ?
                            Object.values(category.children).map((subcategory, subIndex) => (
                                <>
                                    <Text subcategory>{'>>> ' + subcategory.title}</Text>
                                    {subcategory.children && Object.values(subcategory.children).length > 0 ?
                                        Object.values(subcategory.children).map((question, questionIndex) => (
                                            <div style={{ marginLeft: 100 }}>
                                                <Text>--- {question.title}</Text>
                                                <div style={{ marginLeft: 50 }}>
                                                    <Text>Pitanje:</Text>
                                                    <InputArea
                                                        disabled
                                                        style={{ width: '80%' }}
                                                        rows={5}
                                                        value={question.question || ''}
                                                    />
                                                    <Text>Odgovor:</Text>
                                                    <InputArea
                                                        disabled
                                                        style={{ width: '80%' }}
                                                        rows={5}
                                                        value={question.answer || ''}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                        : false
                                    }
                                    <Button question onClick={() => {
                                        setShowQuestionFields(true);
                                        setSelectedCategory(categoryIndex);
                                        setSelectedSubcategory(subIndex);
                                    }}>
                                        Dodaj pitanje
                                    </Button>
                                    <AddQuestion show={showQuestionFields && selectedCategory === categoryIndex && selectedSubcategory === subIndex}>
                                        <Text>Novo pitanje - Naslov:</Text>
                                        <InputArea value={questionTitleInput} onChange={(e) => setQuestionTitleInput(e.target.value)} />
                                        <Text>Novo pitanje - Pitanje:</Text>
                                        <InputArea value={questionInput} onChange={(e) => setQuestionInput(e.target.value)} />
                                        <Text>Novo pitanje - Odgovor:</Text>
                                        <InputArea value={answerInput} onChange={(e) => setAnswerInput(e.target.value)} />
                                        <button onClick={() => addQuestion()}>
                                            OK
                                        </button>
                                    </AddQuestion>
                                </>
                            ))
                            : false
                        }

                        <Button subcategory onClick={() => {
                            setShowSubcategoryFields(true);
                            setSelectedCategory(categoryIndex);
                        }}>
                            Dodaj pod-kategoriju
                        </Button>
                        <AddSubcategory show={showSubcategoryFields && selectedCategory === categoryIndex}>
                            <Text>Nova pod-kategorija:</Text>
                            <InputArea value={subcategoryInput} onChange={(e) => setsubcategoryInput(e.target.value)} />
                            <button onClick={() => addSubcategory()}>
                                OK
                            </button>
                        </AddSubcategory>
                    </div>
                ))
                : false
            }
            <>
                <Button category onClick={() => setShowCategoryFields(true)}>
                    Dodaj kategoriju
                </Button>

                <AddCategory show={showCategoryFields}>
                    <Text>Nova kategorija:</Text>
                    <InputArea value={categoryInput} onChange={(e) => setCategoryInput(e.target.value)} />
                    <button onClick={() => addCategory()}>
                        OK
                    </button>
                </AddCategory>
            </>
        </div>
    )
}

export default withRouter(BazaZnanja);