import React from 'react';

import LoginForm from '../components/authentication/LoginForm'

const Login = () => {
    return (
        <>
            <LoginForm />
        </>
    )
}

export default Login;