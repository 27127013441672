const initialState = {
    userAuthenticated: false,
    showSideDrawer: true,
}

const navigationReducer = (state = initialState, action) => {
    if (action.type === 'TOGGLE_SIDE_DRAWER') {
        return Object.assign({}, state, {
            showSideDrawer: !state.showSideDrawer
        })
    }
    else if (action.type === 'AUTHENTICATE_USER') {
        return Object.assign({}, state, {
            userAuthenticated: true
        })
    }
    else if (action.type === 'REVOKE_AUTHENTICATION') {
        return Object.assign({}, state, {
            userAuthenticated: false
        })
    }
    else return state;
}

export default navigationReducer;