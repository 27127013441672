import React, { useEffect, useState } from 'react';

//Styled and native components
import { Container, Loader } from '../components/tables/Style'
import { AddButton } from '../components/tables/Buttons'

//React components
import { InputArea } from './Styles/Inputs';

//Modules
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import ReactQuill from 'react-quill';
import '../style/editorStyle.css';

//Hooks
import { withRouter } from 'react-router-dom';

//Services
import { addToCollection, queryCollection } from '../services/database'
import { uploadFile } from '../services/storage';

const EditEducations = ({ history }) => {
    //State
    // const [testState, setTestState] = useState('');
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        id: '',
        category: '',
        date: '',
        time: '',
        image: '',
        mobile_image:'',
        title: '',
        description: '',
        ongoing: false,
        type: '',
        left_action: '',
        left_action_text: '',
        right_action: '',
        right_action_text: '',
        order_number: 0,
        times: '',
        prices: '',
        licence: '',
        keywords: '',
        date2: '',
    });
    const [editorData, setEditorData] = useState('');
    const [editorData2, setEditorData2] = useState('');

    //Effect
    useEffect(() => {
        queryCollection('educations', 'id', '==', parseInt(history.location.state.id))
            .then(async result => {
                let tempTimes = '';
                let tempPrices = '';
                let tempLicence = false;

                if (result.data[0].times)
                    tempTimes = await result.data[0].times.join();

                if (result.data[0].prices)
                    tempPrices = await result.data[0].prices.join();

                if (result.data[0].licence)
                    tempLicence = await result.data[0].licence;

                // result.data[0].times ?
                //     setInputs({ ...result.data[0], times: (result.data[0].times).join() })
                //     :
                //     setInputs({ ...result.data[0], times: '' })
                // result.data[0].prices ?
                //     setInputs({ ...result.data[0], prices: (result.data[0].prices).join() })
                //     :
                //     setInputs({ ...result.data[0], prices: '' })
                setInputs({ ...result.data[0], prices: tempPrices, times: tempTimes, licence: tempLicence });
                setEditorData(result.data[0].left_long_text?.replaceAll('style="text-align:', 'class="ql-align-'));
                setEditorData2(result.data[0].right_long_text?.replaceAll('style="text-align:', 'class="ql-align-'));
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Functions
    const handleSubmit = () => {
        addToCollection('educations', (history.location.state.id).toString(),
            {
                ...inputs,
                left_long_text: editorData.replaceAll('class="ql-align-', 'style="text-align:').replaceAll('<p><br></p>','<p></p>'),
                right_long_text: editorData2.replaceAll('class="ql-align-', 'style="text-align:').replaceAll('<p><br></p>','<p></p>'),
                order_number: parseInt(inputs.order_number),
                times: (inputs.times).split(','),
                prices: (inputs.prices).split(',')
            });
        history.push('educations');
    }

    const handlePaste = (event) => {
        const pastedHTML = event.clipboardData.getData('text/html');
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = pastedHTML;
        const processedHTML = processAlignment(tempDiv);
        setEditorData(processedHTML);
      };
    
      const processAlignment = (element) => {
        debugger;
        const alignedElements = element.querySelectorAll('[style*="text-align"]');
        alignedElements.forEach((el) => {
          const textAlign = el.style.textAlign;
          el.removeAttribute('class');
          el.setAttribute('style', `text-align: ${textAlign};`);
        });
        return element.innerHTML;
      };

    return (
        <Container>

            <h1 style={{ color: '#fff' }}>Redni broj:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, order_number: e.target.value });
                }}
                value={inputs.order_number}
            />

            <h1 style={{ color: '#fff', fontSize: 16 }}>Dostupni termini (odvojiti zarezom, npr: 31.12.2020,01.01.2021):</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, times: e.target.value });
                }}
                value={inputs.times}
            />

            <h1 style={{ color: '#fff', fontSize: 16 }}>Opcije plaćanja (odvojiti zarezom, npr: 100 KM, 200 KM):</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, prices: e.target.value });
                }}
                value={inputs.prices}
            />
            <h1 style={{ color: '#fff', fontSize: 16 }}>Ključne riječi:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, keywords: e.target.value });
                }}
                value={inputs.keywords}
            />

            <div style={{ display: 'flex' }}>
                <input type={'checkbox'} checked={inputs.licence} onClick={() => setInputs({ ...inputs, licence: !inputs.licence })} />
                <div style={{ width: 10 }} />
                <h1 style={{ color: '#fff', fontSize: 16 }}>Broj licence u prijavi?</h1>
            </div>

            <h1 style={{ color: '#fff' }}>Katerogrija:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, category: e.target.value });
                }}
                value={inputs.category}
            />

            <h1 style={{ color: '#fff' }}>Datum:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, date: e.target.value });
                }}
                value={inputs.date}
            />
            <h1 style={{ color: '#fff' }}>Datum za sortiranje (yyyymmdd):</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, date2: e.target.value });
                }}
                value={inputs.date2}
            />

            <h1 style={{ color: '#fff' }}>Vrijeme:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, time: e.target.value });
                }}
                value={inputs.time}
            />

            <h1 style={{ color: '#fff' }}>Slika:</h1>
            {loading ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoading(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoading(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, image: result.link });
                            }
                            else alert('Something went wrong with file upload');
                        })
                    }}
                />
            }

            <InputArea
                disabled
                onChange={e => {
                    setInputs({ ...inputs, image: e.target.value });
                }}
                value={inputs.image}
            />
            <h1 style={{ color: '#fff' }}>Mobile Slika:</h1>
            {loading ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoading(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoading(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, mobile_image: result.link });
                            }
                            else alert('Something went wrong with file upload');
                        })
                    }}
                />
            }

            <InputArea
                disabled
                onChange={e => {
                    setInputs({ ...inputs, mobile_image: e.target.value });
                }}
                value={inputs.mobile_image}
            />

            <h1 style={{ color: '#fff' }}>Naslov:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, title: e.target.value });
                }}
                value={inputs.title}
            />

            <h1 style={{ color: '#fff' }}>Opis:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, description: e.target.value });
                }}
                value={inputs.description}
            />

            <h1 style={{ color: '#fff' }}>Da li je edukacija trenutno u toku? :</h1>
            <select
                onChange={e => setInputs({ ...inputs, ongoing: e.target.value })}
                value={inputs.ongoing}
            >
                <option value={true} >Da</option>
                <option value={false} >Ne</option>
            </select>

            <h1 style={{ color: '#fff' }}>Vrsta:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, type: e.target.value });
                }}
                value={inputs.type}
            />

            <h1 style={{ color: '#fff' }}>Lijeva akcija text:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, left_action_text: e.target.value });
                }}
                value={inputs.left_action_text}
            />

            <h1 style={{ color: '#fff' }}>Lijeva akcija:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, left_action: e.target.value });
                }}
                value={inputs.left_action}
            />

            <h1 style={{ color: '#fff' }}>Desna akcija text:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, right_action_text: e.target.value });
                }}
                value={inputs.right_action_text}
            />

            <h1 style={{ color: '#fff' }}>Desna akcija:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, right_action: e.target.value });
                }}
                value={inputs.right_action}
            />

            <div style={{ height: 50 }} />
            <h1 style={{ color: '#fff' }}>Lijevi tekst:</h1>
            <ReactQuill
                value={editorData || ''}
                onChange={setEditorData}
                onPaste={handlePaste}
                modules={{toolbar : [
                    [{ 'font': [] }],
                    ['bold', 'italic', 'underline'],
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'align': [] }],
                    ['omega']
                ]}}
            />

            {/* <CKEditor
                editor={ClassicEditor}
                data={editorData ? editorData : '<p></p>'}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData(data);
                }}
            /> */}

            <div style={{ height: 50 }} />
            <h1 style={{ color: '#fff' }}>Desni tekst:</h1>
            {/* <CKEditor
                editor={ClassicEditor}
                data={editorData2 ? editorData2 : '<p></p>'}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData2(data);
                }}
            /> */}

            <ReactQuill
                value={editorData2 || ''}
                onChange={setEditorData2}
                onPaste={handlePaste}
                modules={{toolbar : [
                    [{ 'font': [] }],
                    ['bold', 'italic', 'underline'],
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'align': [] }],
                    ['omega']
                ]}}
            />

            <div style={{ height: 50 }} />
            
            <AddButton onClick={() => handleSubmit()}>
                <i className="fas fa-plus-circle"></i>
                <h1>Sačuvaj</h1>
            </AddButton>
        </Container >
    )
}

export default withRouter(EditEducations);
