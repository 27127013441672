export const validateEmail = (value) => {
    // const emailRegex = new RegExp(`^[A-Z0-9.!#$%&'*+-/=?^_\`{|}~]+@[A-Z0-9.-]+\\.[A-Z]{2,}$`);

    return new Promise((resolve) => {
        if (!value)
            resolve({
                valid: false,
                message: 'E-mail ne moze biti prazan.'
            });
        // else if (!emailRegex.test(value))
        //     resolve({
        //         valid: false,
        //         message: 'Molimo unesite ispravnu E-mail adresu.'
        //     });
        else
            resolve({
                valid: true,
                message: 'E-mail je validan!'
            });
    });
}

export const validatePassword = (value) => {
    return new Promise((resolve) => {
        if (!value)
            resolve({
                valid: false,
                message: 'Lozinka ne moze biti prazna.'
            });
        else if (value.length < 6)
            resolve({
                valid: false,
                message: 'Lozinka mora biti najmanje 6 znakova.'
            });
        else if (value.length > 255)
            resolve({
                valid: false,
                message: 'Lozinka ne moze biti vise od 255 znakova.'
            })
        else
            resolve({
                valid: true,
                message: 'Lozinka je validna!'
            });
    });
}