import React from 'react';

import { CardContainer, StatCard } from './Style'

const Card = (props) => {
    return (
        <CardContainer>
            <StatCard>
                <i style={{ fontSize: 64, color: props.color }} className={props.icon}></i>
                <div style={{width: '7.5vw', paddingLeft: 15}}>
                    <h1 style={{ margin: 0, color: '#f1f1f1' }}>{props.number}</h1>
                    <p style={{ margin: 0, color: '#f1f1f1' }}>{props.description}</p>
                </div>
            </StatCard>
        </CardContainer>
    )
}

export default Card;