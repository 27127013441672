import React, { useState, useEffect } from 'react';

import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import { UpdateButton } from '../components/tables/Buttons'
import { InputArea } from './Styles/Inputs';

import { getAllCollection, editDocument } from '../services/database';

import { withRouter } from 'react-router-dom';

import firebase from '../config/firebase';

const AppTop10questions = ({ history }) => {
    //State
    const [rows, setRows] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const [allRowsWithoutAnswers, setAllRowsWithoutAnswers] = useState([]);
    const [answer, setAnswer] = useState({
        id: '',
        text: '',
    });
    const [mailchange, setEmailchange] = useState({
        id: '',
        text: '',
    });
    const [showQa, setShowQa] = useState(false);

    useEffect(() => {
        fetchAllQuestions()

        getAllCollection('app_config').then(res => {
            if (res.data.length > 0) {
                console.log('data', res.data[0]);
                setShowQa(res.data[0].show_qa);
            }
        })
    }, []);

    //Functions
    const handleAnswer = (id) => {
        console.log(id);
        try {
            var questionsRef = firebase.database().ref('app_live_questions');
            questionsRef
                .child(id)
                .update({
                    answer: answer.text,
                    timeAnswered: new Date(Date.now()).toLocaleString(),
                })

        }
        catch (error) {
            console.log(error);
        }

        setAnswer({
            id: '',
            text: '',
        });

        fetchAllQuestions();
    }

    const handleEmailchange = (id) => {
        console.log(id);
        try {
            var questionsRef = firebase.database().ref('app_live_questions');
            questionsRef
                .child(id)
                .update({
                    email: mailchange.text,
                    
                })

        }
        catch (error) {
            console.log(error);
        }

        setEmailchange({
            id: '',
            text: '',
        });
    }


    // const handleDelete = (dateId) => {

    // }

    const handleShowQa = () => {
        editDocument('app_config', 'production', 'show_qa', !showQa);

        getAllCollection('app_config').then(res => {
            if (res.data.length > 0) {
                console.log('data', res.data[0]);
                setShowQa(res.data[0].show_qa);
            }
        })
    }

    const fetchAllQuestions = () => {
        var questionsRef = firebase.database().ref('app_live_questions');

        questionsRef.on('value', function (snapshot) {
            if (snapshot.val()) {
                // when page loads, show questions without answers
                setRows(Object.values(snapshot.val()).reverse().filter(r => r.answer === undefined));
                setAllRowsWithoutAnswers(Object.values(snapshot.val()).reverse().filter(r => r.answer === undefined));
                setAllRows(Object.values(snapshot.val()).reverse());
            }
        });
    }
    

    const handleQuestionChange = (e) => {
        if(e.target.value === 'questionsWithoutAnswers') {
            const filteredDataByEmptyAnswer = rows.filter(r => r.answer === undefined);
            setRows(filteredDataByEmptyAnswer);
        } else {
            // when someone select all questions, show all questions
            var questionsRef = firebase.database().ref('app_live_questions');

            questionsRef.on('value', function (snapshot) {
                if (snapshot.val()) {
                    setRows(Object.values(snapshot.val()).reverse());
                }
            });
        }
    }

    return (
        <Container>
            <PageTitle>App Pitanja</PageTitle>
            {/* <div style={{ display: 'flex', marginTop: 30 }}>
                <input type="checkbox" checked={showQa} onClick={() => handleShowQa()} />
                <h1 style={{ fontSize: 16, color: '#fff', marginLeft: 7.5 }}>Prikaži QA u aplikaciji?</h1>
            </div> */}
            <QuestionsContainer>

            <div style={{ marginTop: 30 }}>
                <h3 style={{ fontSize: 16, color: '#fff' }}>Izaberite način filtriranja</h3>
                <select className="question-select" name="questions" id="type-questions-select" onChange={handleQuestionChange}>
                    <option value="questionsWithoutAnswers">Pitanja bez odgovara</option>
                    <option value="allQuestion">Sva pitanja</option>
                </select>
            </div>

            <div>
                <h3 style={{ fontSize: 16, color: '#fff' }}>Ukupan broj postavljenih pitanja: {allRows.length} </h3>
                <h3 style={{ fontSize: 16, color: '#fff' }}>Ukupan broj postavljenih pitanja bez odgovora: {allRowsWithoutAnswers.length} </h3>
            </div>

                <Table>
                    <tr style={{ maxWidth: 300 }}>
                        <th style={{ maxWidth: '5%' }}>Datum Kreiranja</th>
                        <th style={{ maxWidth: '5%' }}>Email</th>
                        {/* <th style={{ maxWidth: '15%' }}>Promjena Email</th>
                        <th style={{ maxWidth: '15%' }}></th> */}
                        <th style={{ maxWidth: '50%' }}>Pitanje</th>
                        <th style={{ maxWidth: '40%' }}>Odgovor</th>
                        <th style={{ maxWidth: '5%' }}></th>
                    </tr>
                    {rows.length > 0 ? rows.map(row =>
                        <tr style={{ maxWidth: 300 }} key={'row_id_' + row.id}>
                            <td style={{ maxWidth: 10 }}>{row.timeCreated}</td>
                            <td style={{ maxWidth: 100 }}>{row.email}</td>
                           {/*  <td style={{ maxWidth: 300 }}><InputArea onClick={() => setEmailchange({ id: row.id, text : row.mailchange ? row.mailchange: ''})}
                                                                     value={row.id === mailchange.id? mailchange.text : row.mailchange ? row.mailchange :''}
                                                                    onChange={e => setEmailchange({id: row.id, text: e.target.value})}/>
                                                                    </td><td style={{ maxWidth: 300 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <UpdateButton onClick={() => handleEmailchange(row.id)}>
                                        <div style={{ width: 10 }} />
                                        <i className="fas fa-edit"></i>
                                        <h4>Promjena maila</h4>
                                        <div style={{ width: 10 }} /></UpdateButton></div></td> */}
                            <td style={{ maxWidth: 400 }}>{row.question}</td>
                            <td style={{ maxWidth: 500 }}>
                                <InputArea
                                    // onClick={() => setAnswer({ id: row.id, text: row.answer ? row.answer : '' })}
                                    value={row.id === answer.id ? answer.text : row.answer ? row.answer : ''}
                                    onChange={e => setAnswer({ id: row.id, text: e.target.value })}
                                />
                            </td>
                            <td style={{ maxWidth: 40 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <UpdateButton onClick={() => handleAnswer(row.id)}>
                                        <div style={{ width: 10 }} />
                                        <i className="fas fa-edit"></i>
                                        <h4>Odgovori</h4>
                                        <div style={{ width: 10 }} />
                                    </UpdateButton>
                                </div>
                            </td>
                     </tr>
                    )
                        : false
                    }
                </Table>
            </QuestionsContainer>
        </Container>
    )
}

export default withRouter(AppTop10questions);