import firebase from '../config/firebase';

//Add sub-category
export async function addSubcategoryService(doc, oldData, subId, value) {
    console.log(oldData);
    try {
        const db = firebase.firestore();
        db.collection('knowledge_vault').doc(doc).update({ children: { ...oldData, [subId]: value } });
    }
    catch (error) {
        console.log(error)
    }
}

//Add question
export async function addQuestionService(doc, oldData, subId, questionId, value) {
    console.log(oldData[subId].children);
    // try {
    //     const db = firebase.firestore();
    //     db.collection('knowledge_vault').doc(doc).update({ children: { ...oldData, [subId]: { ...oldData[subId], [questionId]: value } } });
    // }
    // catch (error) {
    //     console.log(error)
    // }
}