import styled from 'styled-components'

export const Container = styled.div`
    padding-top: 30px;
    min-height: calc(100vh - 55px);
    flex: 1;
    display: flex;
    justify-content: space-evenly;
`

export const CardContainer = styled.div`
    padding: 15px 20px;
    height: 125px;
`

export const StatCard = styled.div`
    background-color: #2d4059;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 15px;
    -webkit-box-shadow: 3px 4px 13px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 13px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 13px 0px rgba(0,0,0,0.75);
`