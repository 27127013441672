import React, { useState, useEffect } from 'react';

import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import { AddButton, UpdateButton, DeleteButton } from '../components/tables/Buttons'
import { InputArea } from './Styles/Inputs';

import { getAllCollection, addToCollection, deleteFromCollection, editDocument } from '../services/database';
import { uploadFile } from '../services/storage';

import { withRouter } from 'react-router-dom';

const FlippingBooks = ({ history }) => {
    //State
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [showAddRow, setShowAddRow] = useState(false);
    const [inputs, setInputs] = useState({
        title: '',
    });
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        getAllCollection('flipping_books').then(result => {
            setRows(result.data);
        })
    }, []);

    //Functions
    const handleAddButton = () => {
        setShowAddRow(true);
    }

    const handleSaveButton = () => {
        if (!loading) {
            if (inputs.title && selectedFile) {
                setLoading(true);
                uploadFile(selectedFile).then(result => {
                    setLoading(false);
                    if (result.status === 200) {
                        setShowAddRow(false);
                        addToCollection(
                            'flipping_books',
                            (rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0).toString(),
                            {
                                id: rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0,
                                title: inputs.title,
                                file: result.link
                            }
                        );
                        getAllCollection('flipping_books').then(result => {
                            setRows(result.data);
                        })
                        setInputs({
                            title: '',
                        });
                    }
                    else alert('Something went wrong with file upload');
                })
            }
        }
        else alert('File is already uploading... Please wait.');
    }

    const handleDelete = (dateId) => {
        deleteFromCollection('flipping_books', dateId);
        getAllCollection('flipping_books').then(result => {
            setRows(result.data);
        })
    }

    const handleUpdate = (id) => {
        history.push('/edit-flipping-books', {
            id: id
        });
    }

    const handleSelectFile = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    }

    return (
        <Container>
            <PageTitle>Flipping Books</PageTitle>
            <QuestionsContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <AddButton onClick={() => handleAddButton()}>
                        <i className="fas fa-plus-circle"></i>
                        <h1>Dodaj</h1>
                    </AddButton>

                    <AddButton
                        backgroundColor={'tomato'}
                        onClick={() => {
                            // eslint-disable-next-line array-callback-return
                            rows.map((row, index) => {
                                editDocument('flipping_books', row.id, 'date2', (row.date.slice(6) + row.date.slice(3, 5) + row.date.slice(0, 2)));
                            })
                        }}>
                        <h1 style={{ textAlign: 'center' }}>Poredaj po datumima</h1>
                    </AddButton>
                </div>
                <Table>
                    <tr>
                        <th style={{ width: '2%' }}>ID</th>
                        <th style={{ width: '11%' }}>Datum objave</th>
                        <th style={{ width: '36%' }}>Naslov</th>
                        <th style={{ width: '36%' }}>Flipping Book</th>
                        <th style={{ width: '15%' }}></th>
                    </tr>
                    <tr style={{ backgroundColor: '#222831', display: showAddRow ? 'revert' : 'none' }}>
                        <td>{rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/ Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0}</td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, title: e.target.value })}
                                value={inputs.title}
                            />
                        </td>
                        <td>
                            <input
                                type={'file'}
                                onChange={(e) => handleSelectFile(e)}
                            />
                        </td>
                        <td>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <AddButton onClick={() => handleSaveButton()}>
                                    <h1>Potvrdi</h1>
                                </AddButton>
                            </div>
                        </td>
                    </tr>
                    {rows.length > 0 ? rows.map(row =>
                        <tr key={'row_id_' + row.id}>
                            <td>{row.id}</td>
                            <td>{row.title}</td>
                            <td><a target={'_none'} href={row.file}>{row.title}</a></td>
                            <td><a target={'_none'} href={row.file}>{row.file}</a></td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <UpdateButton onClick={() => handleUpdate(row.id)}>
                                        <i className="fas fa-edit"></i>
                                    </UpdateButton>
                                    <DeleteButton onClick={() => handleDelete(row.id)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </DeleteButton>
                                </div>
                            </td>
                        </tr>
                    )
                        : false
                    }
                </Table>
            </QuestionsContainer>
        </Container>
    )
}

export default withRouter(FlippingBooks);