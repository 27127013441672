import React, { useState, useEffect } from 'react';

import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import { AddButton, DeleteButton } from '../components/tables/Buttons'
import { InputArea } from './Styles/Inputs';

import { getAllCollection, addToCollection, deleteFromCollection } from '../services/database';

const Pages = () => {
    //State
    const [rows, setRows] = useState([]);
    const [showAddRow, setShowAddRow] = useState(false);
    const [inputs, setInputs] = useState({
        date: '',
        title: ''
    });

    useEffect(() => {
        getAllCollection('pages').then(result => {
            setRows(result.data);
        })
    }, []);

    //Functions
    const handleAddButton = () => {
        setShowAddRow(true);
    }

    const handleSaveButton = () => {
        if (inputs.date && inputs.title) {
            setShowAddRow(false);
            addToCollection(
                'pages',
                (                rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0
).toString(),
                {
                    id:                 rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0
,
                    date: inputs.date,
                    title: inputs.title,
                }
            );
            getAllCollection('pages').then(result => {
                setRows(result.data);
            })
            setInputs({
                date: '',
                title: '',
            });
        }
    }

    const handleDelete = (dateId) => {
        deleteFromCollection('pages', dateId);
        getAllCollection('pages').then(result => {
            setRows(result.data);
        })
    }

    return (
        <Container>
            <PageTitle>Stranice</PageTitle>
            <QuestionsContainer>
                <AddButton onClick={() => handleAddButton()}>
                    <i className="fas fa-plus-circle"></i>
                    <h1>Dodaj</h1>
                </AddButton>
                <Table>
                    <tr>
                        <th style={{ width: '2%' }}>ID</th>
                        <th style={{ width: '13%' }}>Datum objave</th>
                        <th style={{ width: '70%' }}>Naslov</th>
                        <th style={{ width: '15%' }}></th>
                    </tr>
                    <tr style={{ backgroundColor: '#222831', display: showAddRow ? 'revert' : 'none' }}>
                        <td>{                rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0
}</td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
                                value={inputs.date}
                            />
                        </td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, title: e.target.value })}
                                value={inputs.title}
                            />
                        </td>
                        <td>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <AddButton onClick={() => handleSaveButton()}>
                                    <h1>Potvrdi</h1>
                                </AddButton>
                            </div>
                        </td>
                    </tr>
                    {rows.length > 0 ? rows.map(row =>
                        <tr key={'row_id_' + row.id}>
                            <td>{row.id}</td>
                            <td>{row.date}</td>
                            <td>{row.title}</td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    {/* <UpdateButton><i className="fas fa-edit"></i></UpdateButton> */}
                                    <DeleteButton onClick={() => handleDelete(row.id)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </DeleteButton>
                                </div>
                            </td>
                        </tr>
                    )
                        : false
                    }
                </Table>
            </QuestionsContainer>
        </Container>
    )
}

export default Pages;