import React, { useState, useEffect } from 'react';

import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import { AddButton, UpdateButton, DeleteButton } from '../components/tables/Buttons'
import { InputArea } from './Styles/Inputs';

import { getAllCollection, addToCollection, deleteFromCollection, editDocument } from '../services/database';

import { withRouter } from 'react-router-dom';

const Materials = ({ history }) => {
    //State
    const [rows, setRows] = useState([]);
    const [showAddRow, setShowAddRow] = useState(false);
    const [inputs, setInputs] = useState({
        date: '',
        title: '',
        description: '',
        file: '',
        users: []
    });

    useEffect(() => {
        getAllCollection('materials').then(result => {
            if (result.data.length > 0) {
                setRows(result.data);
            }
        })
    }, []);

    //Functions
    const handleAddButton = () => {
        setShowAddRow(true);
    }

    const handleSaveButton = () => {
        if (inputs.date && inputs.title) {
            setShowAddRow(false);
            addToCollection(
                'materials',
                (rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0).toString(),
                {
                    id: rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0,
                    date: inputs.date,
                    title: inputs.title,
                    description: inputs.description,
                    file: inputs.file,
                    users: []
                }
            );
            getAllCollection('materials').then(result => {
                if (result.data.length > 0) {
                    setRows(result.data);
                }
            })
            setInputs({
                date: '',
                title: '',
                description: '',
                file: '',
                users: []
            });
        }
    }

    const handleDelete = (dateId) => {
        deleteFromCollection('materials', dateId);
        getAllCollection('materials').then(result => {
            setRows(result.data);
        })
    }

    const handleUpdate = (id) => {
        history.push('/edit-materials', {
            id: id
        });
    }

    return (
        <Container>
            <PageTitle>Materijali</PageTitle>
            <QuestionsContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <AddButton onClick={() => handleAddButton()}>
                        <i className="fas fa-plus-circle"></i>
                        <h1>Dodaj</h1>
                    </AddButton>
                    <AddButton
                        backgroundColor={'tomato'}
                        onClick={() => {
                            // eslint-disable-next-line array-callback-return
                            rows.map((row, index) => {
                                editDocument('materials', row.id, 'date2', (row.date.slice(6) + row.date.slice(3, 5) + row.date.slice(0, 2)));
                            })
                        }}>
                        <h1 style={{ textAlign: 'center' }}>Poredaj po datumima</h1>
                    </AddButton>
                </div>
                <Table>
                    <tr>
                        <th style={{ width: '1%' }}>ID</th>
                        <th style={{ width: '19%' }}>Datum</th>
                        <th style={{ width: '65%' }}>Naslov</th>
                        <th style={{ width: '15%' }}></th>
                    </tr>
                    <tr style={{ backgroundColor: '#222831', display: showAddRow ? 'revert' : 'none' }}>
                        <td>{rows.length > 0 ? /*parseInt(rows[rows.length - 1].id)*/ Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0}</td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
                                value={inputs.date}
                            />
                        </td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, title: e.target.value })}
                                value={inputs.title}
                            />
                        </td>
                        <td>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <AddButton onClick={() => handleSaveButton()}>
                                    <h1>Potvrdi</h1>
                                </AddButton>
                            </div>
                        </td>
                    </tr>
                    {rows.length > 0 ? rows.map(row =>
                        <tr key={'row_id_' + row.id}>
                            <td>{row.id}</td>
                            <td>{row.date}</td>
                            <td>{row.title}</td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <UpdateButton onClick={() => handleUpdate(row.id)}>
                                        <i className="fas fa-edit"></i>
                                    </UpdateButton>
                                    <DeleteButton onClick={() => handleDelete(row.id)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </DeleteButton>
                                </div>
                            </td>
                        </tr>
                    )
                        : false
                    }
                </Table>
            </QuestionsContainer>
        </Container>
    )
}

export default withRouter(Materials);