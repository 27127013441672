import React from 'react';

import { Container, LogoContainer, Logo, MenuContainer, MenuItems, UserItems, Item, UserItem } from './Styles/MainNavigation'
import { Link } from 'react-router-dom'

const MainNavigation = () => {
    return (
        <Container>
            <LogoContainer>
                <Link to={'/'}><Logo src={require('../../images/logo.png')} /> </Link>
            </LogoContainer>
            <MenuContainer>
                <MenuItems>
                    <Item>
                        <i style={{ color: '#222831', fontSize: 20 }} className="fas fa-bars"></i>
                    </Item>
                </MenuItems>
                <UserItems>
                    <UserItem>
                        <i className="fas fa-user"></i>
                        <div style={{ display: 'flex' }}>
                            <p>Super</p>
                            <div style={{ width: 5 }} />
                            <p>Admin</p>
                        </div>
                        <i className="fas fa-caret-down"></i>
                    </UserItem>
                </UserItems>
            </MenuContainer>
        </Container>
    )
}

export default MainNavigation;