import styled from 'styled-components';

export const Container = styled.div`
    min-height: 520px;
    height: 100vh;
    width: 100vw;
    background-color: #222831;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FormContainer = styled.div`
    width: 450px;
    border-radius: 4px;
    background-color: #2d4059;
    padding-bottom: 10px;
    -webkit-box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.75);
    margin-bottom: 150px;

    @media only screen and (max-width: 768px) {
        width: 95%;
    }
`

export const TitleContainer = styled.div`
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    background-color: #f1f1f1;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    h1 {
        margin: 0;
        margin-top: 15px;
        color: #222831;
        font-size: 18px;
    }
`

export const Logo = styled.img`
    height: 35px;
`

export const ContentContainer = styled.div`
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
`

export const InputField = styled.input`
    height: 40px;
    border-radius: 4px;
    margin: 15px 0;
    font-size: 16px;
    color: #222831;
    padding: 0 15px;
`

export const Label = styled.label`
    font-size: 16px;
    color: ${props => props.error ? 'red' : '#f1f1f1'};
    font-weight: bold;
    margin: ${props => props.margin ? props.margin : '0'};
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
`

export const Button = styled.div`
    width: 150px;
    margin-left: calc((100% - 150px) / 2 );
    border-radius: 4px;
    background-color: #DB0F19;
    cursor: pointer;
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);

    h1 {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #f1f1f1;
    }
`