import React, { useState, useEffect } from 'react';

import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import { AddButton, UpdateButton, DeleteButton } from '../components/tables/Buttons'
import { InputArea } from './Styles/Inputs';

import { getAllCollection, addToCollection, deleteFromCollection } from '../services/database';

const Slider = ({ history }) => {
    //State
    const [rows, setRows] = useState([]);
    const [showAddRow, setShowAddRow] = useState(false);
    const [inputs, setInputs] = useState({
        title: '',
        orderNumber: '',
    });

    useEffect(() => {
        getAllCollection('slider').then(result => {
            if (result.data) {
                setRows(result.data);
            }
        })
    }, []);

    //Functions
    const handleAddButton = () => {
        setShowAddRow(true);
    }

    const handleSaveButton = () => {
        if (inputs.orderNumber) {
            setShowAddRow(false);
            addToCollection(
                'slider',
                (rows.length > 0 ? Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0).toString(),
                {
                    id: rows.length > 0 ? Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0,
                    title: inputs.title,
                    order_number: inputs.orderNumber
                }
            );
            getAllCollection('slider').then(result => {
                setRows(result.data);
            })
            setInputs({
                title: '',
                orderNumber: ''
            });
        }
        else {
            alert('Redni broj je obavezno polje.');
        }
    }

    const handleDelete = (rowId) => {
        deleteFromCollection('slider', rowId);
        getAllCollection('slider').then(result => {
            if (result.data) {
                setRows(result.data);
            }
        })
    }

    const handleEdit = (rowId) => {
        history.push('/edit-slider', {
            id: rowId
        });
    }

    return (
        <Container>
            <PageTitle>Slider</PageTitle>
            <QuestionsContainer>
                <AddButton onClick={() => handleAddButton()}>
                    <i className="fas fa-plus-circle"></i>
                    <h1>Dodaj</h1>
                </AddButton>
                <Table>
                    <tr>
                        <th style={{ width: '2%' }}>ID</th>
                        <th style={{ width: '50%' }}>Naslov</th>
                        <th style={{ width: '28%' }}>Redni broj</th>
                        <th style={{ width: '20%' }}></th>
                    </tr>
                    <tr style={{ backgroundColor: '#222831', display: showAddRow ? 'revert' : 'none' }}>
                        <td>{rows.length > 0 ? Math.max.apply(Math, rows.map(function (o) { return o.id; })) + 1 : 0}</td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, title: e.target.value })}
                                value={inputs.title}
                            />
                        </td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, orderNumber: e.target.value })}
                                value={inputs.orderNumber}
                            />
                        </td>
                        <td>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <AddButton onClick={() => handleSaveButton()}>
                                    <h1>Potvrdi</h1>
                                </AddButton>
                            </div>
                        </td>
                    </tr>
                    {rows.length > 0 ? rows.map(slide =>
                        <React.Fragment key={'slide_' + slide.id}>
                            <tr>
                                <td>{slide.id}</td>
                                <td>{slide.title}</td>
                                <td>{slide.order_number}</td>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        <UpdateButton onClick={() => handleEdit(slide.id)}><i className="fas fa-edit"></i></UpdateButton>
                                        <DeleteButton onClick={() => handleDelete(slide.id)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </DeleteButton>
                                    </div>
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                        : false
                    }
                </Table>
            </QuestionsContainer>
        </Container >
    )
}

export default Slider;