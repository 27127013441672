import React from 'react';

import { Container, DrawerItem, Icon } from './Styles/SideDrawer'
import { Link, withRouter } from 'react-router-dom'
import { useAuth } from "../../contexts/Auth";
import { useLocation } from 'react-router-dom'

import app from '../../config/firebase';

const SideDrawer = (props) => {
    const location = useLocation();
    const  auth = useAuth();
    //Functions
    const handleSignOut = () => {
        app.auth().signOut();
        props.history.push('/login');
    }

    return (
        <Container>

            <DrawerItem unclickable medium>
                <Icon>
                    <i className="fas fa-user-shield"></i>
                </Icon>
                <p>{auth.user.email}</p>
            </DrawerItem>

            <Link to={'/statistics'}>
                <DrawerItem selected={location.pathname === '/statistics' ? true : false} light>
                    <Icon>
                        <i className="fas fa-chart-bar"></i>
                    </Icon>
                    <p>Statistika</p>
                </DrawerItem>
            </Link>
            <Link to={'/slider'}>
                <DrawerItem selected={location.pathname === '/slider' ? true : false} light>
                    <Icon>
                        <i className="fas fa-photo-video"></i>
                    </Icon>
                    <p>Slider</p>
                </DrawerItem>
            </Link>
            <Link to={'/educations'}>
                <DrawerItem selected={location.pathname === '/educations' ? true : false} light>
                    <Icon>
                        <i className="fas fa-calendar-alt"></i>
                    </Icon>
                    <p>Edukacije</p>
                </DrawerItem>
                
            </Link>
            <Link to={'/pretraga'}>
                <DrawerItem selected={location.pathname === '/pretraga' ? true : false} light>
                    <Icon>
                        <i className="fas fa-calendar-alt"></i>
                    </Icon>
                    <p>Pretraga PR-seminari</p>
                </DrawerItem>
            </Link>

            <Link to={'/registered-users'}>
                <DrawerItem selected={location.pathname === '/registered-users' ? true : false} dark>
                    <Icon>
                        <i className="fas fa-users"></i>
                    </Icon>
                    <p>Prijavljeni korisnici</p>
                </DrawerItem>
            </Link>
            <Link to={'/pretplate'}>
                <DrawerItem selected={location.pathname === '/pretplate' ? true : false} dark>
                    <Icon>
                        <i className="fas fa-users"></i>
                    </Icon>
                    <p>Pretplate 2024</p>
                </DrawerItem>
            </Link>
            {/* <Link to={'/payment-categories'}>
                <DrawerItem selected={location.pathname === '/payment-categories' ? true : false} dark>
                    <Icon>
                        <i className="fas fa-money-bill-wave"></i>
                    </Icon>
                    <p>Kategorije placanja</p>
                </DrawerItem>
            </Link> */}
            <Link to={'/news'}>
                <DrawerItem selected={location.pathname === '/news' ? true : false} light>
                    <Icon>
                        <i className="fas fa-newspaper"></i>
                    </Icon>
                    <p>Novosti</p>
                </DrawerItem>
            </Link>
            {/* <Link to={'/news-categories'}>
                <DrawerItem selected={location.pathname === '/news-categories' ? true : false} dark>
                    <Icon>
                        <i className="fas fa-newspaper"></i>
                    </Icon>
                    <p>Kategorije</p>
                </DrawerItem>
            </Link> */}
            <Link to={'/projects'}>
                <DrawerItem selected={location.pathname === '/projects' ? true : false} light>
                    <Icon>
                        <i className="fas fa-tasks"></i>
                    </Icon>
                    <p>Projekti</p>
                </DrawerItem>
            </Link>
            <Link to={'/flipping-books'}>
                <DrawerItem selected={location.pathname === '/flipping-books' ? true : false} light>
                    <Icon>
                        <i className="fas fa-book-open"></i>
                    </Icon>
                    <p>Porezni Savjetnik</p>
                </DrawerItem>
            </Link>
            <Link to={'/e-library'}>
                <DrawerItem selected={location.pathname === '/e-library' ? true : false} light>
                    <Icon>
                        <i className="fas fa-book"></i>
                    </Icon>
                    <p>E-Biblioteka</p>
                </DrawerItem>
            </Link>
            <Link to={'/baza-znanja'}>
                <DrawerItem selected={location.pathname === '/baza-znanja' ? true : false} light>
                    <Icon>
                        <i className="fas fa-calendar-alt"></i>
                    </Icon>
                    <p>Baza znanja</p>
                </DrawerItem>
            </Link>
            <Link to={'/products'}>
                <DrawerItem selected={location.pathname === '/products' ? true : false} light>
                    <Icon>
                        <i className="fas fa-shopping-cart"></i>
                    </Icon>
                    <p>Pretplatnički paketi</p>
                </DrawerItem>
            </Link>
            {/* <Link to={'/product-categories'}>
                <DrawerItem selected={location.pathname === '/product-categories' ? true : false} dark>
                    <Icon>
                        <i className="fas fa-shopping-cart"></i>
                    </Icon>
                    <p>Kategorije</p>
                </DrawerItem>
            </Link> */}
            {/* <Link to={'/magazines'}>
                <DrawerItem selected={location.pathname === '/magazines' ? true : false} light>
                    <Icon>
                        <i className="fas fa-sticky-note"></i>
                    </Icon>
                    <p>Brojevi Magazina</p>
                </DrawerItem>
            </Link> */}
            {/* <Link to={'/magazine-subscriptions'}>
                <DrawerItem selected={location.pathname === '/magazine-subscriptions' ? true : false} dark>
                    <Icon>
                        <i className="fas fa-sticky-note"></i>
                    </Icon>
                    <p>Magazini Pretplate</p>
                </DrawerItem>
            </Link> */}
            {/* <Link to={'/user-subscriptions'}>
                <DrawerItem selected={location.pathname === '/user-subscriptions' ? true : false} light>
                    <Icon>
                        <i className="fas fa-user-tag"></i>
                    </Icon>
                    <p>Korisnicke Pretplate</p>
                </DrawerItem>
            </Link> */}
            {/* <Link to={'/pages'}>
                <DrawerItem selected={location.pathname === '/pages' ? true : false} light>
                    <Icon>
                        <i className="fas fa-file-alt"></i>
                    </Icon>
                    <p>Stranice</p>
                </DrawerItem>
            </Link> */}

            {/* <Link to={'/admins'}>
                <DrawerItem selected={location.pathname === '/admins' ? true : false} light>
                    <Icon>
                        <i className="fas fa-key"></i>
                    </Icon>
                    <p>Administratori</p>
                </DrawerItem>
            </Link> */}
            <Link to={'/shop-products'}>
                <DrawerItem selected={location.pathname === '/shop-products' ? true : false} light>
                    <Icon>
                        <i className="fas fa-file-alt"></i>
                    </Icon>
                    <p>Shop Artikli</p>
                </DrawerItem>
            </Link>
            <Link to={'/shop-orders'}>
                <DrawerItem selected={location.pathname === '/shop-orders' ? true : false} light>
                    <Icon>
                        <i className="fas fa-sticky-note"></i>
                    </Icon>
                    <p>Shop Narudžbe</p>
                </DrawerItem>
            </Link>
            <Link to={'/users'}>
                <DrawerItem selected={location.pathname === '/users' ? true : false} light>
                    <Icon>
                        <i className="fas fa-user-friends"></i>
                    </Icon>
                    <p>Korisnici</p>
                </DrawerItem>
            </Link>
            {/* <Link to={'/banners'}>
                <DrawerItem selected={location.pathname === '/banners' ? true : false} light>
                    <Icon>
                        <i className="fas fa-ad"></i>
                    </Icon>
                    <p>Baneri</p>
                </DrawerItem>
            </Link> */}
            {/* <Link to={'/settings'}>
                <DrawerItem selected={location.pathname === '/settings' ? true : false} light>
                    <Icon>
                        <i className="fas fa-wrench"></i>
                    </Icon>
                    <p>Postavke</p>
                </DrawerItem>
            </Link> */}
            <Link to={'/materials'}>
                <DrawerItem selected={location.pathname === '/materials' ? true : false} light>
                    <Icon>
                        <i className="fas fa-images"></i>
                    </Icon>
                    <p>Materijali</p>
                </DrawerItem>
            </Link>
            <Link to={'/app-questions'}>
                <DrawerItem selected={location.pathname === '/app-questions' ? true : false} light>
                    <Icon>
                        <i className="fas fa-question-circle"></i>
                    </Icon>
                    <p>App Pitanja</p>
                </DrawerItem>
            </Link>
            <Link to={'/app-live-questions'}>
                <DrawerItem selected={location.pathname === '/app-live-questions' ? true : false} light>
                    <Icon>
                        <i className="fas fa-question-circle"></i>
                    </Icon>
                    <p>Top 10 app Pitanja</p>
                </DrawerItem>
            </Link>
            <Link to={'/forum-questions'}>
                <DrawerItem selected={location.pathname === '/forum-questions' ? true : false} light>
                    <Icon>
                        <i className="fas fa-question-circle"></i>
                    </Icon>
                    <p>Forum Pitanja</p>
                </DrawerItem>
            </Link>
            <a href={'https://login.mailchimp.com/'} target={'_none'}>
                <DrawerItem selected={location.pathname === '/mailchimp' ? true : false} light>
                    <Icon>
                        <i className="fab fa-mailchimp"></i>
                    </Icon>
                    <p>Mailchimp</p>
                </DrawerItem>
            </a>
            <div onClick={() => handleSignOut()}>
                <DrawerItem selected={location.pathname === '/logout' ? true : false} light>
                    <Icon>
                        <i className="fas fa-times-circle"></i>
                    </Icon>
                    <p>Odjava</p>
                </DrawerItem>
            </div>
        </Container>
    )
}

export default withRouter(SideDrawer);