import React, { useState, useEffect } from 'react';

import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import { AddButton, DeleteButton } from '../components/tables/Buttons'
import { InputArea } from './Styles/Inputs';

import { getAllCollection, addToCollection, deleteFromCollection } from '../services/database';

const Faq = () => {
    //State
    const [questions, setQuestions] = useState([]);
    const [showAddRow, setShowAddRow] = useState(false);
    const [inputs, setInputs] = useState({
        question: '',
        answer: '',
        featured: false,
    });

    useEffect(() => {
        getAllCollection('faq').then(result => {
            setQuestions(result.data);
        })
    }, []);

    //Functions
    const handleAddButton = () => {
        setShowAddRow(true);
    }

    const handleSaveButton = () => {
        if (inputs.question && inputs.answer) {
            setShowAddRow(false);
            addToCollection(
                'faq',
                (questions.length > 0 ? questions[questions.length - 1].id + 1 : 0).toString(),
                {
                    id: questions.length > 0 ? questions[questions.length - 1].id + 1 : 0,
                    question: inputs.question,
                    answer: inputs.answer,
                    featured: inputs.featured
                }
            );
            getAllCollection('faq').then(result => {
                setQuestions(result.data);
            })
            setInputs({
                question: '',
                answer: '',
                featured: false,
            });
        }
    }

    const handleDelete = (questionId) => {
        deleteFromCollection('faq', questionId);
        getAllCollection('faq').then(result => {
            setQuestions(result.data);
        })
    }

    return (
        <Container>
            <PageTitle>FAQ</PageTitle>
            <QuestionsContainer>
                <AddButton onClick={() => handleAddButton()}>
                    <i className="fas fa-plus-circle"></i>
                    <h1>Dodaj</h1>
                </AddButton>
                <Table>
                    <tr>
                        <th style={{ width: '1%' }}>ID</th>
                        <th style={{ width: '25%' }}>Pitanje</th>
                        <th style={{ width: '50%' }}>Odgovor</th>
                        <th style={{ width: '9%' }}>Izdvojeno</th>
                        <th style={{ width: '15%' }}></th>
                    </tr>
                    <tr style={{ backgroundColor: '#222831', display: showAddRow ? 'revert' : 'none' }}>
                        <td>{questions.length > 0 ? questions[questions.length - 1].id + 1 : 0}</td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, question: e.target.value })}
                                value={inputs.question}
                            />
                        </td>
                        <td>
                            <InputArea
                                rows={8}
                                onChange={(e) => setInputs({ ...inputs, answer: e.target.value })}
                                value={inputs.answer}
                            />
                        </td>
                        <td>
                            <select
                                onChange={(e) => setInputs({ ...inputs, featured: e.target.value })}
                                value={inputs.slider}
                            >
                                <option value={false} label={'Ne'} />
                                <option value={true} label={'Da'} />
                            </select>
                        </td>
                        <td>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <AddButton onClick={() => handleSaveButton()}>
                                    <h1>Potvrdi</h1>
                                </AddButton>
                            </div>
                        </td>
                    </tr>
                    {questions.length > 0 ? questions.map(question =>
                        <React.Fragment key={'question_id_' + question.id}>
                            <tr>
                                <td>{question.id}</td>
                                <td>{question.question}</td>
                                <td>{question.answer}</td>
                                <td>{question.featured ? 'Da' : 'Ne'}</td>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        {/* <UpdateButton><i className="fas fa-edit"></i></UpdateButton> */}
                                        <DeleteButton onClick={() => handleDelete(question.id)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </DeleteButton>
                                    </div>
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                        : false
                    }
                </Table>
            </QuestionsContainer>
        </Container >
    )
}

export default Faq;