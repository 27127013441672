//React
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/Auth';
//Components
import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import TextInput from '../components/elements/TextInput';

//Services
import { queryCollection, getAllCollectionWithDocIdAndCriteria, editDocument , addToCollectionAutoGenerateId } from '../services/database';

//Modules
import CsvDownload from 'react-json-to-csv'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//input area
import { InputArea } from './Styles/Inputs';
import {
    Backdrop,
    ModalContainer,
    TitleContainer,
    ContentContainer,
    ExitButton
} from './Styles/EducationRegistration';
import { UpdateButton, AddButton, CancleButton } from '../components/tables/Buttons';

const RegisteredUsers = () => {
    //Initialization
    const auth = useAuth();

    //State
    const [seminarOptions, setSeminarOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedOption2, setSelectedOption2] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('default');
    const [selectedSort, setSelectedSort] = useState('default');
    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [rowsOriginal, setRowsOriginal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [optionSelected, setOptionSelected] = useState(false);
    const [selectedRegistration, setSelectedRegistration] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [inputs, setInputs] = useState({
        description: '',
    });
    // const [invoice, setInvoice] = useState('');
    const [registrationInputs, setRegistrationInputs] = useState({
        user: '',
        company: '',
        seminar_name: '',
        payment: '',
        email: '',
        address: '',
        city: '',
        phone: '',
        date: '',
        termin: '',
        zip: '',
    });
    const [editPaid, setEditPaid] = useState(false)
    const [editPayment_option, setEditPayment_option] = useState(false)
    const [updateRowId, setUpdateRowId] = useState('')
    //Effect
    useEffect(() => {
        queryCollection('educations', 'ongoing', '==', true)
            .then(res1 => {
                queryCollection('educations', 'ongoing', '==', 'true')
                    .then(res => {
                        setSeminarOptions(res1.data.concat(res.data));
                    })
                    .catch(e => console.log(e))
            })
            .catch(e => console.log(e))
    }, []);

    useEffect(() => {
        // getAllCollection('user_subscriptions', 'ongoing', '==', true).then(response => {
        //     console.log('NEW RESPONSE', response)
        // })
    }, [])

    const handleUpdate = (id) => {
        setUpdateRowId(id);
        setEditPaid(true);
        setEditPayment_option(true);
        
        // history.push('/edit-educations', {
        //     id: id
        // });
    }
    //Functions
    const handleSelectSeminar = (seminar) => {
        setSelectedOption(seminar);
        getAllCollectionWithDocIdAndCriteria('user_subscriptions', 'seminar_name', '==', seminar).then(result => {
            setRows2(result.data);
            setRowsOriginal(result.data);
            setSelectedFilter('default')
            setSelectedSort('default')
        }).catch(e => console.log(e))
        queryCollection('user_subscriptions', 'seminar_name', '==', seminar)
            .then(res => {
                setRows(res.data);
                setEditPaid(false);
                setUpdateRowId('');
                setInputs({ ...inputs, description: ''});
            })
            .catch(e => console.log(e))
    }

    const handleSelectFilter = (e) => {
        setSelectedFilter(e.target.value)
        setSelectedSort('default')
        if(e.target.value === 'default'){
            setRows2(rowsOriginal)
        }else if(e.target.value === 'placeno'){
            setRows2(rowsOriginal.filter(i => i.data.status === 'plaćeno'))
        }else if(e.target.value === 'neplaceno'){
            setRows2(rowsOriginal.filter(i => i.data.status !== 'plaćeno'))
        }else if(e.target.value === 'otkazano'){
            setRows2(rowsOriginal.filter(i => i.data.status == 'otkazano'))
        }else if(e.target.value === 'ugovor/odluka'){
            setRows2(rowsOriginal.filter(i => i.data.status == 'ugovor/odluka'))
        }else if(e.target.value === 'snimak'){
            setRows2(rowsOriginal.filter(i => i.data.status == 'snimak'))
        }
    }
    const handleSelectSort = (e) => {
        setSelectedSort(e.target.value)
        if(e.target.value === 'datum'){
            setRows2(rows2.sort((a, b) => {
                const dateA = new Date(a.data.date.replace(/(.*)GMT.*/, '$1'));
                const dateB = new Date(b.data.date.replace(/(.*)GMT.*/, '$1'));
                return dateA - dateB;
              }))
        }else if(e.target.value === 'ime'){
            setRows2(rows2.sort((a, b) => {
                return a.data.user.localeCompare(b.data.user)
              }))
        }
    }
    const handleSelectSeminarRegistration = (e) => {
        setSelectedRegistration(e.target.value);
        setRegistrationInputs({ ...registrationInputs, seminar_name: e.target.value });
        if(e.target.value === 'default')
            setOptionSelected(false);
        else
            setOptionSelected(true);
    }

    const generateFileName = () => {
        const date = new Date();
        const temp = date.toString();
        let fileName = `${temp.slice(4, 7)}-${temp.slice(8, 10)}-${temp.slice(11, 15)}-${temp.slice(16, 18)}-${temp.slice(19, 21)}.csv`

        return fileName;
    }
    function HandleStatus(id, statusDB, userName, email, seminarTitle) {
        const handleSelectChange = (event) => {
            const newStatusValues = event.target.value
            editDocument('user_subscriptions', id, 'status', newStatusValues).then(result => {
                addToCollectionAutoGenerateId(
                    'user_subscription_logs',
                    {
                        admin: auth.user.email,
                        date: new Date().toString(),
                        newStatus: newStatusValues,
                        previousStatus: statusDB?statusDB:'',
                        user: userName,
                        seminarName: seminarTitle
                    }
                ).then(res => {
                    console.log('Saved insert');
                });
                getAllCollectionWithDocIdAndCriteria('user_subscriptions', 'seminar_name', '==', selectedOption).then(result => {
                    setRowsOriginal(result.data);
                    if(selectedSort === 'datum'){
                        setRows2(result.data.sort((a, b) => {
                            const dateA = new Date(a.data.date.replace(/(.*)GMT.*/, '$1'));
                            const dateB = new Date(b.data.date.replace(/(.*)GMT.*/, '$1'));
                            return dateA - dateB;
                          }))
                    }else if(selectedSort === 'ime'){
                        setRows2(result.data.sort((a, b) => {
                            return a.data.user.localeCompare(b.data.user)
                          }))
                    }
                    if(selectedFilter !== 'default'){
                        if(selectedFilter === 'placeno'){
                            setRows2(result.data.filter(i => i.data.status === 'plaćeno'))
                        }else if(selectedFilter === 'neplaceno'){
                            setRows2(result.data.filter(i => i.data.status !== 'plaćeno'))
                        }else if(selectedFilter === 'otkazano'){
                            setRows2(result.data.filter(i => i.data.status == 'otkazano'))
                        }else if(selectedFilter === 'ugovor/odluka'){
                            setRows2(result.data.filter(i => i.data.status == 'ugovor/odluka'))
                        }else if(selectedFilter === 'snimak'){
                            setRows2(result.data.filter(i => i.data.status == 'snimak'))
                        }
                    }else{
                        setRows2(result.data)
                    }
                    if(newStatusValues === 'plaćeno'){
                        const postData = {
                            userName: userName,
                            email: email,
                            seminarName: seminarTitle
                        }
                        const URL = 'https://us-central1-revicon-1dadd.cloudfunctions.net'
                        fetch(`${URL}/subscriptionPaid`, {
                            method: 'POST',
                            body: JSON.stringify(postData),
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(res => {
                            if (res.status === 200) {
                                console.log("Email uspjesno poslat");
                            }
                        }).catch((e) => {
                            console.log(e)
                        })
                    }
                }).catch(e => console.log(e))
            });
        };
        return (<>
            {
                <div style={{width : '130px'}}>
                    <div>
                        <select style={{ width: '100%', marginTop: 15, marginBottom: 25, height: 35 }} value={statusDB?statusDB:''} onChange={handleSelectChange}>
                            <option key='0' value={''}>Izaberite opciju</option>
                            <option key='1' value={'plaćeno'}>plaćeno</option>
                            <option key='2' value={'ugovor/odluka'}>ugovor/odluka</option>
                            <option key='3' value={'otkazano'}>otkazano</option>
                            <option key='3' value={'snimak'}>snimak</option>
                        </select>
                    </div>
                </div>
            }
        </>)
    }

    function HandleStatus2(id, statusDB, userName, email, seminarTitle) {
        const handleSelectChange2 = (event) => {
            var dataSaved = false;
            const newStatusValues = event.target.value
            editDocument('user_subscriptions', id, 'acces_data_sent', "true");
            debugger;
            editDocument('user_subscriptions', id, 'status', newStatusValues).then(result => {
                addToCollectionAutoGenerateId(
                    'user_subscription_logs',
                    {
                        admin: auth.user.email,
                        date: new Date().toString(),
                        newStatus: newStatusValues,
                        previousStatus: statusDB?statusDB:'',
                        user: userName,
                        userEmail: email,
                        seminarName: seminarTitle
                    }
                ).then(res => {
                    dataSaved = true;
                    console.log('Saved insert');
                });
                debugger;
                getAllCollectionWithDocIdAndCriteria('user_subscriptions', 'seminar_name', '==', selectedOption).then(result => {
                    setRowsOriginal(result.data);
                    if(selectedSort === 'datum'){
                        setRows2(result.data.sort((a, b) => {
                            const dateA = new Date(a.data.date.replace(/(.*)GMT.*/, '$1'));
                            const dateB = new Date(b.data.date.replace(/(.*)GMT.*/, '$1'));
                            return dateA - dateB;
                          }))
                    }else if(selectedSort === 'ime'){
                        setRows2(result.data.sort((a, b) => {
                            return a.data.user.localeCompare(b.data.user)
                          }))
                    }
                    if(selectedFilter !== 'default'){
                        if(selectedFilter === 'placeno'){
                            setRows2(result.data.filter(i => i.data.status === 'plaćeno'))
                        }else if(selectedFilter === 'neplaceno'){
                            setRows2(result.data.filter(i => i.data.status !== 'plaćeno'))
                        }else if(selectedFilter === 'otkazano'){
                            setRows2(result.data.filter(i => i.data.status == 'otkazano'))
                        }else if(selectedFilter === 'ugovor/odluka'){
                            setRows2(result.data.filter(i => i.data.status == 'ugovor/odluka'))
                        }else if(selectedFilter === 'snimak'){
                            setRows2(result.data.filter(i => i.data.status == 'snimak'))
                        }
                    }else{
                        setRows2(result.data)
                    }
                    // if (newStatusValues === 'sent' && dataSaved === true) {
                    //     const postData = {
                    //         userName: userName,
                    //         email: email,
                    //         userEmail: email,
                    //         seminarName: seminarTitle
                    //     }
                    //     const URL = 'https://us-central1-revicon-1dadd.cloudfunctions.net'
                    //     fetch(`${URL}/subscriptionPaid`, {
                    //         method: 'POST',
                    //         body: JSON.stringify(postData),
                    //         headers: {
                    //             'Content-Type': 'application/json'
                    //         }
                    //     }).then(res => {
                    //         if (res.status === 200) {
                    //             console.log("Email uspjesno poslat");
                    //         }
                    //     }).catch((e) => {
                    //         console.log(e)
                    //     })
                    // }
                }).catch(e => console.log(e))
            });
        };
        return (<>
            {
                <div style={{width : '130px'}}>
                    <div>
                        <select style={{ width: '100%', marginTop: 15, marginBottom: 25, height: 35 }} value={statusDB?statusDB:''} onChange={handleSelectChange2}>
                            <option key='0' value={''}>Izaberite opciju</option>
                            <option key='1' value={'sent'}>Posalji email</option>
                            <option key='2' value={''}>Nije plaćeno</option>
                        </select>
                    </div>
                </div>
            }
        </>)
    }

    const handleAddButton = (id) => {
            editDocument('user_subscriptions', id, 'description', inputs.description).then(result => {
                getAllCollectionWithDocIdAndCriteria('user_subscriptions', 'seminar_name', '==', selectedOption).then(result => {
                    setRows2(result.data);
                    setEditPaid(false);
                    setUpdateRowId('');
                    setSelectedFilter('default');
                    setSelectedSort('default');
                    setInputs({ ...inputs, description: ''});
                }).catch(e => console.log(e))
            });
    }

    const handleAddButtonpayment = (id) => {
        editDocument('user_subscriptions', id, 'payment_option', inputs.payment_option).then(result => {
            getAllCollectionWithDocIdAndCriteria('user_subscriptions', 'seminar_name', '==', selectedOption).then(result => {
                setRows2(result.data);
                setEditPaid(false);
                setUpdateRowId('');
                setSelectedFilter('default');
                setSelectedSort('default');
                setInputs({ ...inputs, payment_option: ''});
            }).catch(e => console.log(e))
        });
}
    const handleNewSubscription = () => {
        setShowModal(true);
    }
    const closeModal = () => {
        setOptionSelected(true);
        setShowModal(false);
    }

    const handleNewSubscriptionSubmit = () => {
        addToCollectionAutoGenerateId(
            'user_subscriptions',
            {
                user:  registrationInputs.user,
                date: startDate.toString(),
                seminar_name: registrationInputs.seminar_name,
                payment_option: registrationInputs.payment,
                city: registrationInputs.city,
                address: registrationInputs.address,
                phone: registrationInputs.phone,
                company: registrationInputs.company,
                email: registrationInputs.email,
                termin: registrationInputs.termin,
                zip: registrationInputs.zip,
            }
        ).then(res => {
            console.log('Saved insert');
            setRegistrationInputs({...registrationInputs,  user: '', company: '', email: '', address: '', city: '', phone: '', date: new Date(), zip: '' });
            setSelectedRegistration('');
            setOptionSelected(false);
        });
    }

    function handleDescription(id, description) {
        return (<>
            { description? 
            <div style={{width : '130px'}}>
                <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, description: e.target.value });
                        }}
                        value={inputs.description}
                    />
                    </div>
                </div> :
                <div style={{width : '130px'}}>
                    <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, description: e.target.value });
                        }}
                        value={inputs.description}
                    />
                    </div>
                </div>
            }
        </>)
    }
    function handlePayment_option(id, payment_option) {
        return (<>
            { payment_option? 
            <div style={{width : '130px'}}>
                <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, payment_option: e.target.value });
                        }}
                        value={inputs.payment_option}
                    />
                    </div>
                </div> :
                <div style={{width : '130px'}}>
                    <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, payment_option: e.target.value });
                        }}
                        value={inputs.payment_option}
                    />
                    </div>
                </div>
            }
        </>)
    }

    const searchByInvoice = (e) => {

        if (e.target.value === ''){
            setRows2(rowsOriginal)
        } else {
            setRows2(rows2.filter(row => row.data.invoiceId?.includes(e.target.value.trim())));
        }
    }

    return (
        <Container>
            <Backdrop show={showModal}>
            <ModalContainer>
                {
                 <ExitButton
                            onClick={() => closeModal()}
                        >
                            <img
                                alt={'Zatvori'}
                                src={require('../images/exit.png')}
                            />
                        </ExitButton>
                }
                <TitleContainer>
                    <h2>
                        Odaberite edukaciju
                    </h2>
                    <select className="select-box" style={{ width: '30%', marginTop: 15, height: 35 }} value={selectedRegistration} onChange={handleSelectSeminarRegistration}>
                    <option value={'default'}>Izaberite edukaciju</option>
                    {seminarOptions.length > 0
                        ? seminarOptions.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item.title}>{item.title}</option>
                        ))
                        : false
                    }
                    </select>
                    <h6>
                        Prijava na edukaciju
                    </h6>
                </TitleContainer>

                <ContentContainer show={optionSelected}>
                <TextInput
                    value={registrationInputs.user}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, user: text })}
                    placeholder={'ime učesnika'}
                    label={'Ime i prezime:'}
                />
                <TextInput
                    value={registrationInputs.company}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, company: text })}
                    placeholder={'firma'}
                    label={'Firma:'}
                />
                <TextInput
                    value={registrationInputs.email}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, email: text })}
                    placeholder={'email'}
                    label={'Email:'}
                />
                <TextInput
                    value={registrationInputs.address}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, address: text })}
                    placeholder={'adresa'}
                    label={'Adresa:'}
                />
                <TextInput
                    value={registrationInputs.city}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, city: text })}
                    placeholder={'grad'}
                    label={'Grad:'}
                />
                <TextInput
                    value={registrationInputs.zip}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, zip: text })}
                    placeholder={'zip'}
                    label={'Zip:'}
                />
                <TextInput
                    value={registrationInputs.phone}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, phone: text })}
                    placeholder={'telefon'}
                    label={'Telefon:'}
                />
                <select className="select-box" style={{ width: '30%', marginTop: 15, height: 35 }} value={registrationInputs.payment} onChange={e => setRegistrationInputs({ ...registrationInputs, payment: e.target.value })}>
                    <option value={''}>Opcija plaćanja:</option>
                    {optionSelected
                        ? seminarOptions.find(seminar => seminar.title === registrationInputs.seminar_name).prices.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item}>{item}</option>
                        ))
                        : false
                    }
                </select>
                <select style={{ width: '30%', marginTop: 15, height: 35 }} value={registrationInputs.termin} onChange={e => setRegistrationInputs({ ...registrationInputs, termin: e.target.value })}>
                    <option value={''}>Termin:</option>
                    {optionSelected
                        ? seminarOptions.find(seminar => seminar.title === registrationInputs.seminar_name).times.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item}>{item}</option>
                        ))
                        : false
                    }
                </select>
                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '10px', marginBottom: '5px' }}>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '5px', marginBottom: '10px' }}>
                    <AddButton onClick={() => handleNewSubscriptionSubmit()}>
                        <i className="fas fa-plus-circle"></i>
                        <h1>Dodaj</h1>
                    </AddButton>
                </div>
                </ContentContainer>
            </ModalContainer>
            </Backdrop>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PageTitle>Prijavljeni korisnici</PageTitle>
                {rows.length > 0
                    ? <CsvDownload style={{ marginLeft: 20 }} data={rows} filename={generateFileName()} >Preuzmi CSV</CsvDownload>
                    : false
                }
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <select style={{ width: '30%', marginTop: 15, height: 35 }} onChange={e => handleSelectSeminar(e.target.value)}>
                    <option value={null}>Izaberite edukaciju</option>
                    {seminarOptions.length > 0
                        ? seminarOptions.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item.title}>{item.title}</option>
                        ))
                        : false
                    }
                </select>
                <div>
                    <AddButton onClick={() => handleNewSubscription()}>
                        <i className="fas fa-plus-circle"></i>
                        <h1>Dodaj</h1>
                    </AddButton>
                </div>
            </div>
            <QuestionsContainer>
                <div>
                    <h4 style={{ color: '#f1f1f1'}}>Seminar: {selectedOption} {selectedOption ? `(${rows2.length})` : false}</h4>
                    <select style={{ width: '10%', height: 35, marginRight: 20  }} value={selectedFilter} onChange={handleSelectFilter}>
                        <option value={'default'}>Odaberite opciju za filtriranje</option>
                        <option value={'placeno'}>Plaćeno</option>
                        <option value={'neplaceno'}>Neplaćeno</option>
                        <option value={'otkazano'}>Otkazano</option>
                        <option value={'ugovor/odluka'}>ugovor/odluka</option>
                        <option value={'snimak'}>snimak</option>
                    </select>
                    <select style={{ width: '15%', height: 35, marginRight: 20 }} value={selectedSort} onChange={handleSelectSort}>
                        <option value={'default'}>Odaberite opciju za sortiranje</option>
                        <option value={'datum'}>Datum prijave</option>
                        <option value={'ime'}>Ime i prezime</option>
                    </select>
                    <input id='search' style={{height: 30, width: '15%'}} disabled={!rows.length > 0} placeholder='Pretraži po predračunu' onChange={(e) => {searchByInvoice(e)}} />
                </div>
                <Table style={{ marginBottom: 20 }} rows2={rows2} key={`seminar_data_${0}`}>
                <tbody>
                            <tr style={{ maxWidth: 300 }}>
                                <th style={{ maxWidth: '10%' }}>Kompanija:</th>
                                 <th style={{ maxWidth: '10%' }}>Grad: </th> 
                                <th style={{ maxWidth: '10%' }}>Opcija plaćanja: </th>
                                <th style={{ maxWidth: '10%' }}>Ime i prezime: </th>
                                <th style={{ maxWidth: '10%' }}>Email: </th>
                                <th style={{ maxWidth: '10%' }}>Telefon: </th>
                                <th style={{ maxWidth: '10%' }}>Datum prijave: </th>
                                <th style={{ maxWidth: '10%' }}>Termin: </th>
                                <th style={{ maxWidth: '10%' }}>Adresa: </th> 
                                <th style={{ maxWidth: '10%' }}>Predracun: </th>
                                <th style={{ maxWidth: '10%' }}>Poštanski: </th> 
                                <th style={{ maxWidth: '10%' }}>Status</th>
                                <th style={{ maxWidth: '10%' }}>Opis: </th>
                                <th style={{ width: '15%' }}>Status</th>
                                <th style={{ width: '15%' }}>izmjeni cijenu</th>
                                
                           </tr>
                {rows2.length > 0
                    ? rows2.map((item, index) => (   
                           <tr style={{ maxWidth: 300, background: item.data.status === "plaćeno" ? "#E30710" : item.data.status === "snimak" ? "green" : "#2d4059" }} key={'item_id_' + index}>
                               <td style={{maxwith: '10%'}}>{item.data.company}</td> 
                                 <td style={{maxwith: '10%'}}>{item.data.city}</td>  
                                <td style={{maxwith: '10%'}}>{item.data.payment_option}</td>
                                <td style={{maxwith: '10%'}}>{item.data.user}</td>
                                <td style={{maxwith: '10%'}}>{item.data.email}</td>
                                <td style={{maxwith: '10%'}}>{item.data.phone}</td>
                                <td style={{maxwith: '10%'}}>{item.data.date}</td>
                                <td style={{maxwith: '10%'}}>{item.data.termin}</td>
                                <td style={{maxwith: '10%'}}>{item.data.address}</td>
                                <td style={{maxwith: '10%'}}>{item.data.invoiceId}</td>
                                <td style={{maxwith: '10%'}}>{item.data.zip}</td>
                                <td style={{maxwith: '10%'}}>{item.data.status}</td>
                                <td style={{maxwith: '10%'}}>{editPaid && rows2[updateRowId] === item ? handleDescription(item.id, item.data.description) : item.data.description}{editPaid && rows2[updateRowId] === item ?<div style={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}><div style={{ marginRight:'10px'}}><AddButton  onClick={() => handleAddButton(item.id)}><i className="fas fa-plus-circle"></i><h1>Dodaj</h1></AddButton></div><CancleButton onClick={() => {setEditPaid(false);setUpdateRowId('');setInputs({ ...inputs, description: ''});}}><i className="fas fa-ban"></i><h1>Ponisti</h1></CancleButton></div>:<UpdateButton onClick={() => handleUpdate(index)}><i className="fas fa-edit"></i></UpdateButton>}</td>
                                <td style={{ width: '15%' }}>
                                    {item.data.status === "plaćeno" ? HandleStatus2(item.id, item.data.status, item.data.user, item.data.email, selectedOption2) : (item.data.status === "sent" && item.data.acces_data_sent === "true") ? <div></div> : HandleStatus(item.id, item.data.status, item.data.user, item.data.email, selectedOption)}
                                </td> 
                                <td style={{maxwith: '10%'}}>
                                    {editPayment_option && rows2[updateRowId] === item ? handlePayment_option(item.id, item.data.payment_option) : item.data.payment_option}{editPayment_option && rows2[updateRowId] === item ?<div style={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}><div style={{ marginRight:'10px'}}><AddButton  onClick={() => handleAddButtonpayment(item.id)}><i className="fas fa-plus-circle"></i><h1>Dodaj</h1></AddButton></div><CancleButton onClick={() => {setEditPayment_option(false);setUpdateRowId('');setInputs({ ...inputs, payment_option: ''});}}><i className="fas fa-ban"></i><h1>Ponisti</h1></CancleButton></div>:<UpdateButton onClick={() => handleUpdate(index)}><i className="fas fa-edit"></i></UpdateButton>}</td>
                            </tr>   
                       
                    ))
                    : false
                }</tbody></Table>

            </QuestionsContainer>
        </Container>
    )
}

export default RegisteredUsers;