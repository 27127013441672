import firebase from '../config/firebase';

//READ WHOLE COLLECTION
export async function getAllCollection(collection) {
    try {
        const db = firebase.firestore();
        const data = await db.collection(collection).get();
        const dataResult = data.docs.map(doc => doc.data())

        return new Promise((resolve) => {
            if (data) {
                resolve({
                    data: dataResult
                })
            }
        })
    }
    catch (error) {
        console.log(error)
    }
}

//READ WHOLE COLLECTION WITH DOCUMENT ID
export async function getAllCollectionWithDocId(collection) {
    try {
        const db = firebase.firestore();
        const data = await db.collection(collection).get();
        const dataResult = data.docs.map(doc => ({id: doc.id, data: doc.data()}))

        return new Promise((resolve) => {
            if (data) {
                resolve({
                    data: dataResult
                })
            }
        })
    }
    catch (error) {
        console.log(error)
    }
}
//READ WHOLE COLLECTION WITH DOCUMENT ID
export async function getAllCollectionWithDocIdAndValuesInCondition(collection, year, packageSub) {
    try {
        const db = firebase.firestore();
        
        var data
        if(year !== ''){
            if(packageSub === 'basic')
                data = await db.collection(collection).where('year', '==', year).where('package_name', 'in',['BASIC', 'Pretplatnički paket: BASIC']).get();
            else if ( packageSub === 'bv')
                data = await db.collection(collection).where('year', '==', year).where('package_name', 'in',['BEST VALUE', 'Pretplatnički paket: BEST VALUE', 'Pretplatnički paket: BETTER VALUE']).get();
            else if ( packageSub === 'premium')
                data = await db.collection(collection).where('year', '==', year).where('package_name', 'in',['PREMIUM', 'Pretplatnički paket: PREMIUM']).get();
            else if ( packageSub === 'rc')
                data = await db.collection(collection).where('year', '==', year).where('package_name', 'in',['REVICON CLUB', 'Pretplatnički paket: REVICON CLUB']).get();
            else 
                data = await db.collection(collection).where('year', '==', year).get();
        }
        else{
            if(packageSub === 'basic')
                data = await db.collection(collection).where('package_name', 'in',['BASIC', 'Pretplatnički paket: BASIC']).get();
            else if ( packageSub === 'bv')
                data = await db.collection(collection).where('package_name', 'in',['BEST VALUE', 'Pretplatnički paket: BEST VALUE', 'Pretplatnički paket: BETTER VALUE']).get();
            else if ( packageSub === 'premium')
                data = await db.collection(collection).where('package_name', 'in',['PREMIUM', 'Pretplatnički paket: PREMIUM']).get();
            else if ( packageSub === 'rc')
                data = await db.collection(collection).where('package_name', 'in',['REVICON CLUB', 'Pretplatnički paket: REVICON CLUB']).get();
            else 
                data = await db.collection(collection).get();
        }
        const dataResult = data.docs.map(doc => ({id: doc.id, data: doc.data()}))

        return new Promise((resolve) => {
            if (data) {
                resolve({
                    data: dataResult
                })
            }
        })
    }
    catch (error) {
        console.log(error)
    }
}
//READ WHOLE COLLECTION WITH DOCUMENT ID AND CRITERIA
export async function getAllCollectionWithDocIdAndCriteria(collection, queryField, comparission, queryCriteria) {
    try {
        const db = firebase.firestore();
        const data = await db.collection(collection).where(queryField, comparission, queryCriteria).get();
        const dataResult = data.docs.map(doc => ({id: doc.id, data: doc.data()}))

        return new Promise((resolve) => {
            if (data) {
                resolve({
                    data: dataResult
                })
            }
        })
    }
    catch (error) {
        console.log(error)
    }
}
//QUERY COLLECTION WITH DESIRED CRITERIA
export async function queryCollection(collection, queryField, comparission, queryCriteria) {
    try {
        const db = firebase.firestore();
        const data = await db.collection(collection).where(queryField, comparission, queryCriteria).get();
        const dataResult = data.docs.map(doc => doc.data())

        return new Promise((resolve) => {
            if (data) {
                resolve({
                    data: dataResult
                })
            }
        })
    }
    catch (error) {
        console.log(error)
    }
}
export async function queryCollectionByDocId(collection, id) {
    try {
        const db = firebase.firestore();
        const data = await db.collection(collection).doc(id).get();
        return new Promise((resolve) => {
            if (data) {
                resolve({
                    data: data.data()
                })
            }
        })
    }
    catch (error) {
        console.log(error)
    }
}

//ADD DOC TO COLLECTION
export async function addToCollection(collection, doc, data) {
    try {
        const db = firebase.firestore();
        db.collection(collection).doc(doc).set(data);
    }
    catch (error) {
        console.log(error)
    }
}

//ADD DOC TO COLLECTION
export async function addToCollectionAutoGenerateId(collection, data) {
    try {
        const db = firebase.firestore();
        db.collection(collection).add(data);
    }
    catch (error) {
        console.log(error)
    }
}

//DELETE DOC FROM COLLECTION
export async function deleteFromCollection(collection, doc) {
    try {
        const db = firebase.firestore();
        await db.collection(collection).doc(doc.toString()).delete();
    }
    catch (error) {
        console.log(error)
    }
}

//EDIT DOCUMENT
export async function editDocument(collection, doc, field, value) {
    try {
        const db = firebase.firestore();
        await db.collection(collection).doc(doc.toString()).update({ [field]: value });
    }
    catch (error) {
        console.log(error)
    }
}

//Revoke access permissions
export async function revokePermissions(doc) {
    try {
        const db = firebase.firestore();
        db.collection('users').doc(doc.toString()).update(
            {
                'baza_znanja': false,
                'biblioteka': false,
                'porezni': false
            }
        );
    }
    catch (error) {
        console.log(error)
    }
}
