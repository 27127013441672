import React, { useEffect, useState } from 'react';

//Styled and native components
import { Container, Loader } from '../components/tables/Style'
import { AddButton } from '../components/tables/Buttons'

//React components
import { InputArea } from './Styles/Inputs';

//Hooks
import { withRouter } from 'react-router-dom';

//Services
import { addToCollection, queryCollection } from '../services/database'
import { uploadFile } from '../services/storage';

const EditMaterials = ({ history }) => {
    //State
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        id: '',
        date: '',
        file: '',
        title: '',
        description: '',
        users: '',
    });

    //Effect
    useEffect(() => {
        queryCollection('materials', 'id', '==', parseInt(history.location.state.id))
            .then(async result => {
                if (result.data.length > 0) {
                    let tempUsers = '';

                    if (result.data[0].users)
                        tempUsers = await result.data[0].users.join();

                    setInputs({ ...result.data[0], users: tempUsers });
                }
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Functions
    const handleSubmit = () => {
        addToCollection('materials', (history.location.state.id).toString(),
            {
                ...inputs,
                users: (inputs.users).split(','),
            });
        history.push('materials');
    }

    return (
        <Container>

            <h1 style={{ color: '#fff', fontSize: 16 }}>Korisnici kojima je materijal dostupan (odvojiti zarezom, npr: revicon@info.com, kontakt@revicon.com):</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, users: e.target.value });
                }}
                value={inputs.users}
            />

            <h1 style={{ color: '#fff' }}>Datum:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, date: e.target.value });
                }}
                value={inputs.date}
            />

            <h1 style={{ color: '#fff' }}>Dokument (file):</h1>
            {loading ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoading(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoading(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, file: result.link });
                            }
                            else alert('Došlo je do greške prilikom upload-a');
                        })
                    }}
                />
            }

            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, file: e.target.value });
                }}
                value={inputs.file}
            />

            <h1 style={{ color: '#fff' }}>Naslov:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, title: e.target.value });
                }}
                value={inputs.title}
            />

            <h1 style={{ color: '#fff' }}>Opis:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, description: e.target.value });
                }}
                value={inputs.description}
            />

            <div style={{ height: 50 }} />

            <AddButton onClick={() => handleSubmit()}>
                <i className="fas fa-plus-circle"></i>
                <h1>Sačuvaj</h1>
            </AddButton>
        </Container >
    )
}

export default withRouter(EditMaterials);
