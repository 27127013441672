import React, { useEffect, useState } from 'react';

import { Container } from '../components/statistics/Style'
import Card from '../components/statistics/Card'

import { getAllCollection } from '../services/database';

const Statistics = () => {
    //State
    // const [stats, setStats] = useState([]);
    const [educations, setEducations] = useState(0);
    const [projects, setProjects] = useState(0);
    const [news, setNews] = useState(0);
    const [porezni, setPorezni] = useState(0);
    const [biblioteka, setBiblioteka] = useState(0);

    useEffect(() => {
        // getAllCollection('statistics').then(result => {
        //     setStats(result.data);
        // })
        getAllCollection('educations').then(res => {
            setEducations(res.data.length);
        })
        getAllCollection('projects').then(res => {
            setProjects(res.data.length);
        })
        getAllCollection('news').then(res => {
            setNews(res.data.length);
        })
        getAllCollection('flipping_books').then(res => {
            setPorezni(res.data.length);
        })
        getAllCollection('e_library').then(res => {
            setBiblioteka(res.data.length);
        })
    }, [])

    return (
        <Container>
            {/* {stats.length > 0 ?
                stats.map((stat, index) =>
                    <Card
                        key={`stat-${index}`}
                        icon={stat.icon}
                        color={stat.color}
                        number={stat.amount}
                        description={stat.title}
                    />
                ) : false
            } */}
            <Card
                icon={'fas fa-calendar-alt'}
                color={'#7266BA'}
                number={educations}
                description={'Edukacije'}
            />

            <Card
                icon={'fas fa-tasks'}
                color={'#23B7E5'}
                number={projects}
                description={'Projekti'}
            />

            <Card
                icon={'fas fa-newspaper'}
                color={'#E30710'}
                number={news}
                description={'Novosti'}
            />

            <Card
                icon={'fas fa-question-circle'}
                color={'#27C24C'}
                number={porezni + biblioteka}
                description={'Izdanja'}
            />
        </Container>
    )
}

export default Statistics;