import styled from 'styled-components';

export const Container = styled.div`
    width: calc(100% - 280px);
    min-height: calc(100vh - 55px);
    padding: 20px 15px;
`

export const QuestionsContainer = styled.div`
    background-color: #2d4059;
    width: calc(100% - 30px);
    border-radius: 4px;
    margin-top: 15px;
    padding: 20px 15px;
    -webkit-box-shadow: 3px 4px 13px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 13px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 13px 0px rgba(0,0,0,0.75);
    overflow-x: scroll;
`

export const PageTitle = styled.h1`
    margin: 0;
    color: #f1f1f1;
    font-weight: bold;
    font-size: 18px;
`

export const Table = styled.table`
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;

    th {
        font-weight: bold;
    }

    td, th {
        border: 1px solid #ddd;
        padding: 6px;
        max-width: fit-content;
        font-size: 14px;
        color: #f1f1f1;


        // border: .5px solid #f1f1f1;
        // text-align: left;
        // padding: 8px;
        // color: #f1f1f1;
        // height: 35px;
    }

    a {
        color: powderblue;
        text-decoration: underline;
    }
`

export const Loader = styled.div`
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`