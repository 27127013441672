import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-bottom: 5px;

    label {
        padding-bottom: 5px;
        color: #222;
        font-size: 16px;
        font-weight: 500;
    }

    input {
        height: 40px;
        border: ${props => props.error ? '2px solid #E74C3C' : '2px solid #d3d3d3'};
        border-radius: 8px;
        width: calc(100% - 34px);
        background-color: ${props => props.disabled ? '#d3d3d3' : '#f1f1f1'};
        padding: 0px 15px;
        color: #666;

        ::placeholder { 
            color: #666;
        }
    }
`