//React
import React, { useState } from 'react';

//Components
import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import { AddButton, DeleteButton } from '../components/tables/Buttons'

//Modules
import uuid from 'react-uuid'

//Services
import {
    getAllCollection,
    addToCollection,
    editDocument,
    deleteFromCollection,
    queryCollection
} from '../services/database';
import { createUser } from '../services/users';

//Constants
// import { IMPORT_USERS } from '../constants/importUsers';
// import {
//     BIBLIOTEKA,
//     POREZNI,
//     PERMISSIONS_2022_BAZA_ZNANJA,
//     POREZNI_I_BIBLIOTEKA
// } from '../constants/2022permissions';

const Users = () => {
    //State
    const [loadedAll, setLoadedAll] = useState(false);
    const [rows, setRows] = useState([]);
    const [showAddRow, setShowAddRow] = useState(false);
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
        
       
       
        forum: false,
        active: false
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [searchMessage, setSearchMessage] = useState('');

    //Functions
    const registerUser = (email, password) => {
        createUser(email, password);
    }

    // const headlessRegisterUser = (email, password, porezni, biblioteka, baza_znanja) => {
    //     queryCollection('users', 'email', '==', email)
    //         .then(result => {
    //             if (result.data.length > 0) {
    //                 console.log(`${email} već postoji`);
    //             }
    //             else {
    //                 const UNIQUE_ID = uuid();
    //                 addToCollection(
    //                     'users',
    //                     (UNIQUE_ID
    //                     ).toString(),
    //                     {
    //                         id: UNIQUE_ID,
    //                         email: email,
    //                         password: password,
    //                         porezni: porezni,
    //                         biblioteka: biblioteka,
    //                         baza_znanja: baza_znanja
    //                     }
    //                 );
    //                 registerUser(email, password.toString());
    //             }
    //         })
    //         .catch(e => console.log(e))
    // }

    const handleAddButton = () => {
        setShowAddRow(true);
    }

    const handleSaveButton = () => {
        const UNIQUE_ID = uuid();
        if (inputs.email && inputs.password) {
            setShowAddRow(false);
            addToCollection(
                'users',
                (UNIQUE_ID
                ).toString(),
                {
                    id: UNIQUE_ID,
                    email: inputs.email,
                    password: inputs.password,
                    
                    
                    forum: inputs.forum,
                    active: inputs.active
                }
            );
            registerUser(inputs.email, inputs.password);
            setInputs({
                email: '',
                password: '',
               
                forum: false,
                active: false
            });
        }
    }

    const handleDelete = (userId) => {
        deleteFromCollection('users', userId);
        getAllCollection('users').then(result => {
            setRows(result.data);
        })
    }

    const handleLoadAllUsers = () => {
        getAllCollection('users').then(result => {
            setRows(result.data);
            setLoadedAll(true);
        })
    }

    const handleSearchUser = () => {
        if (searchTerm) {
            setSearchMessage('');
            queryCollection('users', 'email', '==', searchTerm).then(result => {
                if (result.data.length > 0) {
                    setSearchMessage('');
                    setRows(result.data);
                }
                else {
                    setSearchMessage('Korisnik ne postoji');
                }
            })
        }
        else {
            setSearchMessage('Molimo unesite Email traženog korisnika');
        }
    }

    // const sendRegistrationEmail = (email, password) => {
    //     fetch(`https://us-central1-revicon-1dadd.cloudfunctions.net/userRegistration?email=${email}&password=${password}`, {
    //         method: 'GET'
    //     })
    // }

        // const updatePermissions = () => {
        // REVOKE ALL PERMISSIONS
        // getAllCollection('users')
        //     .then(result => {
        //         // console.log(result);
        //         result.data.forEach(user => {
        //             // console.log(user.id)
        //             if (user.id) {
        //                 revokePermissions(user.id)
        //             }
        //         })
        //     })
        //     .catch(e => console.log(e))

        // ADD BAZA PERMISSIONS
        // PERMISSIONS_2022_BAZA_ZNANJA.forEach(email => {
        //     queryCollection('users', 'email', '==', email)
        //         .then(result => {
        //             if (result.data.length > 0) {
        //                 // console.log(`${email} već postoji`);
        //                 // console.log(result.data[0]);
        //                 editDocument('users', result.data[0].id, 'baza_znanja', true)
        //             }
        //             else {
        //                 console.log(`NOT FOUND: ${email}`)
        //             }
        //         })
        //         .catch(e => console.log(e))
        // })

        // // ADD POREZNI I BIBLIOTEKA PERMISSIONS
        // POREZNI_I_BIBLIOTEKA.forEach(email => {
        //     queryCollection('users', 'email', '==', email)
        //         .then(result => {
        //             if (result.data.length > 0) {
        //                 // console.log(`${email} već postoji`);
        //                 // console.log(result.data[0]);
        //                 editDocument('users', result.data[0].id, 'porezni', true)
        //                 editDocument('users', result.data[0].id, 'biblioteka', true)
        //             }
        //             else {
        //                 console.log(`NOT FOUND: ${email}`)
        //             }
        //         })
        //         .catch(e => console.log(e))
        // })

        // // ADD BIBLIOTEKA PERMISSIONS
        // BIBLIOTEKA.forEach(email => {
        //     queryCollection('users', 'email', '==', email)
        //         .then(result => {
        //             if (result.data.length > 0) {
        //                 // console.log(`${email} već postoji`);
        //                 // console.log(result.data[0]);
        //                 editDocument('users', result.data[0].id, 'biblioteka', true)
        //             }
        //             else {
        //                 console.log(`NOT FOUND: ${email}`)
        //             }
        //         })
        //         .catch(e => console.log(e))
        // })

        // // ADD POREZNI PERMISSIONS
        // POREZNI.forEach(email => {
        //     queryCollection('users', 'email', '==', email)
        //         .then(result => {
        //             if (result.data.length > 0) {
        //                 // console.log(`${email} već postoji`);
        //                 // console.log(result.data[0]);
        //                 editDocument('users', result.data[0].id, 'porezni', true)
        //             }
        //             else {
        //                 console.log(`NOT FOUND: ${email}`)
        //             }
        //         })
        //         .catch(e => console.log(e))
        // })
        // }

    // const addNewUsersList = () => {
    //     IMPORT_USERS.map(user => {
    //         sendRegistrationEmail(user.email, user.password);
    //     })
    // }

    return (
        <Container>
            <PageTitle>Korisnici</PageTitle>

            {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <AddButton backgroundColor={'orange'} onClick={() => addNewUsersList()}>
                    <h1 style={{ textAlign: 'center' }}>Posalji mailove</h1>
                </AddButton>
            </div> */}

            {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <AddButton backgroundColor={'orange'} onClick={() => updatePermissions()}>
                    <h1 style={{ textAlign: 'center' }}>Updated permissions</h1>
                </AddButton>
            </div> */}

            {!loadedAll ?
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <AddButton backgroundColor={'orange'} onClick={() => handleLoadAllUsers()}>
                            <h1 style={{ textAlign: 'center' }}>Učitaj sve korisnike (!)</h1>
                        </AddButton>
                    </div>
                    <div style={{ marginTop: 30, display: 'flex' }}>
                        <input
                            style={{ borderRadius: 8, width: 200, paddingLeft: 7.5, paddingRight: 7.5 }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm}
                        />
                        <div style={{ width: 10 }} />
                        <AddButton onClick={() => handleSearchUser()}>
                            <i className="fas fa-search"></i>
                            <h1 style={{ textAlign: 'center' }}>Traži</h1>
                        </AddButton>
                    </div>
                    {searchMessage ?
                        <h6 style={{ color: 'tomato', margin: 0, marginTop: 5, fontSize: 14 }}>{searchMessage}</h6>
                        : false
                    }
                </div>
                : false
            }

            <QuestionsContainer>
                <AddButton onClick={() => handleAddButton()}>
                    <i className="fas fa-plus-circle"></i>
                    <h1>Dodaj</h1>
                </AddButton>
                <div style={{ height: 50 }} />
                {/* <AddButton onClick={() => sendRegistrationMails()}>
                    <i className="fas fa-plus-circle"></i>
                    <h1>Posalji mail svima</h1>
                </AddButton> */}
                <Table>
                    <tr>
                        <th style={{ width: '24%' }}>Email</th>
                        <th style={{ width: '15%' }}>Password</th>
                        <th style={{ width: '16%' }}>active</th>
                        {/* <th style={{ width: '16%' }}>El. biblioteka</th> */}
                        <th style={{ width: '15%' }}>FORUM</th>
                        <th style={{ width: '20%' }}></th>
                    </tr>
                    <tr style={{ backgroundColor: '#222831', display: showAddRow ? 'revert' : 'none' }}>
                        <td>
                            <input
                                onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
                                value={inputs.email}
                            />
                        </td>
                        <td>
                            <input
                                onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                                value={inputs.password}
                            />
                        </td>
                        <td>
                            <input
                                type={'checkbox'}
                                checked={inputs.active}
                                onChange={e => setInputs({ ...inputs, active: !inputs.active })}
                            />
                        </td>
                        {/* <td>
                            <input
                                type={'checkbox'}
                                checked={inputs.biblioteka}
                                onChange={e => setInputs({ ...inputs, biblioteka: !inputs.biblioteka })}
                            />
                        </td> */}
                        <td>
                            <input
                                type={'checkbox'}
                                checked={inputs.forum}
                                onChange={e => setInputs({ ...inputs, forum: !inputs.forum })}
                            />
                        </td>
                        <td>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <AddButton onClick={() => handleSaveButton()}>
                                    <h1>Potvrdi</h1>
                                </AddButton>
                            </div>
                        </td>
                    </tr>
                    {rows.length > 0 ? rows.map(row =>
                        <tr key={'row_id_' + row.id}>
                            <td>{row.email}</td>
                            <td>{row.password}</td>
                            <td>
                                <input type="checkbox" checked={row.active} onChange={e => {
                                    editDocument('users', row.id, 'active', !row.active);
                                    getAllCollection('users').then(result => {
                                        setRows(result.data);
                                    })
                                }} />
                            </td>
                            {/* <td>
                                <input type="checkbox" checked={row.biblioteka} onChange={e => {
                                    editDocument('users', row.id, 'biblioteka', !row.biblioteka);
                                    getAllCollection('users').then(result => {
                                        setRows(result.data);
                                    })
                                }} />
                            </td> */}
                            <td>
                                <input type="checkbox" checked={row.forum} onChange={e => {
                                    editDocument('users', row.id, 'forum', !row.forum);
                                    getAllCollection('users').then(result => {
                                        setRows(result.data);
                                    })
                                }} />
                            </td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    {/* <UpdateButton><i className="fas fa-edit"></i></UpdateButton> */}
                                    <DeleteButton onClick={() => handleDelete(row.id)}><i className="fas fa-trash-alt"></i></DeleteButton>
                                </div>
                            </td>
                        </tr>
                    )
                        : false
                    }
                </Table>
            </QuestionsContainer>
        </Container>
    )
}

export default Users;		
