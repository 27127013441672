import React, { useEffect, useState } from 'react';


//Styled and native components
import { Container, Loader } from '../components/tables/Style'
import { AddButton } from '../components/tables/Buttons'

//React components
import { InputArea } from './Styles/Inputs';

//Modules
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ReactQuill from 'react-quill';
import '../style/editorStyle.css';

//Hooks
import { withRouter } from 'react-router-dom';

//Services
import { addToCollection, queryCollection } from '../services/database'
import { uploadFile } from '../services/storage';

const EditELibrary = ({ history }) => {
    //State
    const [loading, setLoading] = useState(false);
    const [loadingDoc, setLoadingDoc] = useState(false);
    const [inputs, setInputs] = useState({
        id: '',
        date: '',
        title: '',
        image: '',
        file: '',
        date2: '',
    });
    const [editorData, setEditorData] = useState('');

    //Effect
    useEffect(() => {
        queryCollection('e_library', 'id', '==', parseInt(history.location.state.id))
            .then(result => {
                console.log(result);
                if (result.data.length > 0) {
                    setInputs(result.data[0]);
                    setEditorData(result.data[0].long_text);
                }
            })
    }, []);

    //Functions
    const handleSubmit = () => {
        addToCollection('e_library', (history.location.state.id).toString(), { ...inputs, long_text: editorData });
        history.push('e-library');
    }

    return (
        <Container>
            <h1 style={{ color: '#fff' }}>Naslov:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, title: e.target.value });
                }}
                value={inputs.title}
            />

            <h1 style={{ color: '#fff' }}>Datum:</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, date: e.target.value });
                }}
                value={inputs.date}
            />

               <h1 style={{ color: '#fff' }}>Datum(format-yyyymmdd):</h1>
            <InputArea
                onChange={e => {
                    setInputs({ ...inputs, date2: e.target.value });
                }}
                value={inputs.date2}
            />

            <h1 style={{ color: '#fff' }}>Slika:</h1>
            {loading ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoading(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoading(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, image: result.link });
                            }
                            else alert('Something went wrong with file upload');
                        })
                    }}
                />
            }

            <InputArea
                disabled
                onChange={e => {
                    setInputs({ ...inputs, image: e.target.value });
                }}
                value={inputs.image}
            />

            <h1 style={{ color: '#fff' }}>Dokument:</h1>
            {loadingDoc ?
                <Loader />
                :
                <input
                    type='file'
                    onChange={e => {
                        setLoadingDoc(true);
                        uploadFile(e.target.files[0]).then(result => {
                            setLoadingDoc(false);
                            if (result.status === 200) {
                                setInputs({ ...inputs, file: result.link });
                            }
                            else alert('Something went wrong with file upload');
                        })
                    }}
                />
            }

            <InputArea
                disabled
                onChange={e => {
                    setInputs({ ...inputs, file: e.target.value });
                }}
                value={inputs.file}
            />

            <div style={{ height: 50 }} />

            {/* <CKEditor
                editor={ClassicEditor}
                data={editorData ? editorData : '<p></p>'}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorData(data);
                }}
            /> */}
            <ReactQuill
                theme={'snow'}
                value={editorData ? editorData : '<p></p>'}
                onChange={e => {
                    console.log(e);
                    setEditorData(e);
                }}
                modules={{ toolbar: [[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline'], [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }], [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], ['link']] }}
                formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'align']}
            />

          

            <div style={{ height: 50 }} />

            <AddButton onClick={() => handleSubmit()}>
                <i className="fas fa-plus-circle"></i>
                <h1>Sačuvaj</h1>
            </AddButton>
        </Container>
    )
}

export default withRouter(EditELibrary);
